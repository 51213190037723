import React, { useContext, useState } from 'react'
import SstContext from '../contexts/SstContext';
import Header from './Header';
import '../css/navbar.css';
import Logout from './Logout';
import SubNavbar from './SubNavbar';
import { Link } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';
import MessageBox from './MessageBox';
import DarkThemeButton from './DarkThemeButton';
import Hamburger from './Hamburger';
import { useEffect } from 'react';

const Navbar = (props) => {
    const loadingBarColor = "#295184";
    const [isclicked, setIsClicked] = useState(false);
    const authenticate = useContext(SstContext);
    useEffect(() => {
        if (authenticate.isLogin){
            if (isclicked){
                document.getElementById("actualNavGrid").style.left = "0";
            }else{
                document.getElementById("actualNavGrid").style.left = "-50vw";
            }
        }
    }, [isclicked]);
    const items = {
        "Booking": [{ url: "/gcn/entry", title: "GCN Entry" }, { url: "/gcn/modifyandprint", title: "Modify & Print" }],
        "Truck Details": [{ url: "/thc/entry", title: "THC Entry" }, { url: "/thc/modifyandprint", title: "Modify & Print" }],
        "Billing": [{ url: "/bill/submission", title: "Bill Submission" }, { url: "/bill/collection", title: "Bill Collection" }, { url: "/bill/print", title: "Bill Print" }],
        "Accounts": [{ url: "/cheque", title: "Cheque" }],
        "SST Own": [{ url: "/mh12um0724", title: "MH12UM0724" }, { url: "/mh12sx9094", title: "MH12SX9094" }],
        "Reports": [{ url: "/brokerreport", title: "Broker Report" }, { url: "/monthlybookingreport", title: "Monthly Booking" }, { url: "/billsubmissionreport", title: "Bill Sub.Report" }, { url: "/outstandingbillreport", title: "Outstanding Bill" }],
        "POD": [{ url: "/trackandviewpod", title: "Track & View" }, { url: "/uploadpod", title: "Upload" }],
        "Admin": [{ url: "/client", title: "Client" }, { url: "/destination", title: "Destination" }, { url: "/broker", title: "Broker" }, { url: "/owner", title: "Truck Owner" }, { url: "/driver", title: "Truck Driver" }, { url: "/truckDetail", title: "Truck Details" }, { url: "/description", title: "Description" }, { url: "/packagetype", title: "Pkg Type" }, { url: "/envelop", title: "Envelop" }]
    }
    const handleLogout = () => {
        authenticate.handleProgress(10);
        authenticate.handleAuthenticate(false);
        authenticate.handleUserName("");
        authenticate.handleProgress(50);
        authenticate.handleAdmin(false);
        document.body.classList.remove("backgroundWhite");
        let rootStyles = document.querySelector(":root");
        if (authenticate.theme === "dark") {
            authenticate.handleTheme();
            rootStyles.style.setProperty("--strokeColor", "black");
            rootStyles.style.setProperty("--fillColor", "black");
            rootStyles.style.setProperty("--offwhite", "#efefef");
            document.body.style.backgroundColor = "white";
            rootStyles.style.setProperty("--customBlue", "rgb(41, 81, 132)");
            rootStyles.style.setProperty("--customLightBlue", "rgb(163 187 218)");
            rootStyles.style.setProperty("--inputBoxBorder", "0.15rem solid rgb(195 213 236)");
            rootStyles.style.setProperty("--inputBoxBackgroundColor", "white");
            rootStyles.style.setProperty("--colorScheme", "light");
        }
        authenticate.handleProgress(100);
    }
    return (
        <>
            <LoadingBar color={loadingBarColor} progress={authenticate.progress} onLoaderFinished={() => authenticate.handleProgress(0)} />
            {!authenticate.isLogin && <nav>
                <Header contactNumber="+91 9689388938" mailId="sstransline99@gmail.com" whatsappNumber="+91 9422331609" />
                <div id="LogoDiv">
                    <img src={props.symbol} alt="SST Symbol" id="SstSymbol" />
                    <img src={props.logo} alt="SST Logo" id="LogoImg" />
                </div>
                <div className="navbaritem">About us</div>
                <div>
                    <Link to="/trackandviewpod" className="navbaritem">Track</Link>
                </div>
            </nav>}
            {authenticate.isLogin && <>
                <nav id='loginNavbar'>
                    <div id="LogoDivLogin">
                        <img src={props.symbol} alt="SST Symbol" id="SstSymbol" />
                        <img src={props.logoLogin} alt="SST Logo" id="LogoImg" />
                    </div>
                    <div id="marqueeGrid">
                        <marquee behavior="scroll" direction="left">SST Live Server</marquee>
                    </div>
                    <div id="welcomeGrid">
                        <div>Welcome : {authenticate.userName}</div>
                    </div>
                    <div id="darkThemeGrid">
                        <DarkThemeButton />
                    </div>
                    <div id="logoutGrid">
                        <Link to="/" onClick={handleLogout}>
                            <Logout />
                        </Link>
                    </div>
                </nav>
                <section id='actualNavContainer'>
                    <Hamburger isClicked={isclicked} setIsClicked={setIsClicked}/>
                    <section id="actualNavGrid">
                        {Object.keys(items).map(element => {
                            return <SubNavbar key={element} itemsIncluded={items[element]} itemMainTitle={element} />
                        })}
                    </section>
                </section>
                {authenticate.message && <MessageBox setMessage={authenticate.setMessage} message={authenticate.message} />}
            </>}
        </>
    )
}

export default Navbar
