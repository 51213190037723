import React, { useContext, useEffect } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';

const AddDescription = () => {
    const authenticate = useContext(SstContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            }else{
                document.title = "SSTransline | Description";
            }
        }
        // eslint-disable-next-line
    }, []);
    const items = [{ "label": "Product", "id": "product", "type": "text" }];
    const validateDescription = () => {
        if (document.getElementById("product").value === "") {
            authenticate.setMessage({message:"Product Cannot Be Blank"});
            return false;
        }
        return true;
    }
    const handleAddDescription = () => {
        if (validateDescription()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            let descriptionData = {
                "product" : document.getElementById("product").value
              }
            const addDescriptionUrl = `${authenticate.apiUrl}/api/description/`;
            fetch(addDescriptionUrl, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                body: JSON.stringify(descriptionData)
            }).then(response => {
                if (response.status === 200) {
                    authenticate.setMessage({message:"Product Added Successfully", navigateHome:true});
                } else {
                    authenticate.setMessage({message:response.statusText});
                }
            });
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="addDescriptionSection">
                <div className="heading">
                    <div>Add Product</div>
                </div>
                <div className="descriptionSection">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="submit" id="addProduct" className="navbarButton" value="Add Product" onClick={handleAddDescription} />
                </div>
            </section>}
        </>
    )
}

export default AddDescription
