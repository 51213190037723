import './App.css';
import Navbar from './components/Navbar';
import SstState from './contexts/SstState';
import sstSymbol from "./images/SSTSymbol.webp";
import sstLogoBlueFill from "./images/SSTLogoBlueFill.webp";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from './components/Home';
import GcnEntry from './components/GcnEntry';
import GcnModifyAndPrint from './components/GcnModifyAndPrint';
import BillSubmission from './components/BillSubmission';
import BillPrint from './components/BillPrint';
import AddClient from './components/AddClient';
import AddDestination from './components/AddDestination';
import AddBroker from './components/AddBroker';
import AddDescription from './components/AddDescription';
import AddPackageType from './components/AddPackageType';
import AddOwner from './components/AddOwner';
import AddDriver from './components/AddDriver';
import ThcEntry from './components/ThcEntry';
import ThcModifyAndPrint from './components/ThcModifyAndPrint';
import AddTruckDetails from './components/AddTruckDetails';
import SstOwn from './components/SstOwn';
import BrokerReport from './components/BrokerReport';
import MonthlyBooking from './components/MonthlyBooking';
import BillSubmissionReport from './components/BillSubmissionReport';
import OutstandingBill from './components/OutstandingBill';
import Envelop from './components/Envelop';
import Cheque from './components/Cheque';
import TrackAndViewPod from './components/TrackAndViewPod';
import UploadPod from './components/UploadPod';

function App() {
  return (
    <>
      <BrowserRouter>
        <SstState>
          <Navbar symbol={sstSymbol} logo={sstLogoBlueFill} logoLogin={sstLogoBlueFill}/>
          <Routes>
            <Route path='/' element={<Home symbol={sstSymbol}/>} />
            <Route path='/gcn/entry' element={<GcnEntry edit={false}/>} />
            <Route path='/gcn/modifyandprint' element={<GcnModifyAndPrint />} />
            <Route path='/gcn/modify/:gcnNo' element={<GcnEntry edit={true}/>} />
            <Route path='/bill/submission' element={<BillSubmission edit={false}/>} />
            <Route path='/bill/collection' element={<BillSubmission edit={true}/>} />
            <Route path='/bill/print' element={<BillPrint />} />
            <Route path='/client' element={<AddClient />} />
            <Route path='/destination' element={<AddDestination />} />
            <Route path='/broker' element={<AddBroker />} />
            <Route path='/description' element={<AddDescription />} />
            <Route path='/packagetype' element={<AddPackageType />} />
            <Route path='/owner' element={<AddOwner />} />
            <Route path='/driver' element={<AddDriver />} />
            <Route path='/thc/entry' element={<ThcEntry edit={false} />} />
            <Route path='/thc/modifyandprint' element={<ThcModifyAndPrint />} />
            <Route path='/thc/modify/:thcNo' element={<ThcEntry edit={true} />} />
            <Route path='/truckDetail' element={<AddTruckDetails />} />
            <Route path='/mh12um0724' element={<SstOwn truckNo="mh12um0724" />} />
            <Route path='/mh12sx9094' element={<SstOwn truckNo="mh12sx9094" />} />
            <Route path='/brokerreport' element={<BrokerReport />} />
            <Route path='/monthlybookingreport' element={<MonthlyBooking />} />
            <Route path='/billsubmissionreport' element={<BillSubmissionReport />} />
            <Route path='/outstandingbillreport' element={<OutstandingBill />} />
            <Route path='/envelop' element={<Envelop />} />
            <Route path='/cheque' element={<Cheque />} />
            <Route path='/trackandviewpod' element={<TrackAndViewPod />} />
            <Route path='/uploadpod' element={<UploadPod />} />
          </Routes>
        </SstState>
      </BrowserRouter>
    </>
  );
}

export default App;
