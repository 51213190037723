import React, { useContext, useEffect, useState } from 'react';
import '../css/gcnEntry.css';
import SstContext from '../contexts/SstContext';
import { useNavigate, useParams } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';

const GcnEntry = (props) => {
  const authenticate = useContext(SstContext);
  const [gcnNumber, setGcnNumber] = useState("");
  const [state, setState] = useState([]);
  const [location, setLocation] = useState([]);
  const [client, setClient] = useState([]);
  const [description, setDescription] = useState([]);
  const [packageType, setPackageType] = useState([]);
  const [deliveryState, setDeliveryState] = useState("select");
  const [basicFreight, setBasicFreight] = useState(0);
  const [loadingCharges, setLoadingCharges] = useState(0);
  const [craneCharges, setCraneCharges] = useState(0);
  const [fovCharges, setFovCharges] = useState(0);
  const [fuelSurcharges, setFuelSurcharges] = useState(0);
  const [lrCharges, setLrCharges] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [rcm, setRcm] = useState("Yes");
  const [grandTotal, setGrandTotal] = useState(0);
  const [amountInWords, setAmountInWords] = useState(0);
  const [sgstUtgst, setSgstUtgst] = useState(0);
  const [cgst, setCgst] = useState(0);
  const [igst, setIgst] = useState(0);
  const [selectedGcnData, setSelectedGcnData] = useState([]);
  const pageData = useParams();
  const navigate = useNavigate();
  const handleGcnNumber = (event) => {
    setGcnNumber(event.target.value);
  }
  const checkRcm = (value, subTotal) => {
    if (value === "Yes") {
      setGrandTotal(subTotal);
      checkAmountInWords(subTotal);
    } else {
      setGrandTotal(Math.round(parseFloat(subTotal) + parseFloat(sgstUtgst) + parseFloat(cgst) + parseFloat(igst)));
      checkAmountInWords(Math.round(parseFloat(subTotal) + parseFloat(sgstUtgst) + parseFloat(cgst) + parseFloat(igst)));
    }
  }
  const checkDeliveryState = (value, subTotal) => {
    if (value === "1" || value === 1) {
      let localSgstUgst = Math.round(parseFloat(subTotal) * 2.5 / 100);
      let localCgst = Math.round(parseFloat(subTotal) * 2.5 / 100);
      let localIgst = 0;
      setSgstUtgst(Math.round(parseFloat(subTotal) * 2.5 / 100));
      setCgst(Math.round(parseFloat(subTotal) * 2.5 / 100));
      setIgst(0);
      if (rcm === "No") {
        setGrandTotal(Math.round(parseFloat(subTotal) + localSgstUgst + localCgst + localIgst));
        checkAmountInWords(Math.round(parseFloat(subTotal) + localSgstUgst + localCgst + localIgst));
      }
    } else {
      let localSgstUgst = 0;
      let localCgst = 0;
      let localIgst = Math.round(parseFloat(subTotal) * 5 / 100);
      setSgstUtgst(0);
      setCgst(0);
      setIgst(Math.round(parseFloat(subTotal) * 5 / 100));
      if (rcm === "No") {
        setGrandTotal(Math.round(parseFloat(subTotal) + localSgstUgst + localCgst + localIgst));
        checkAmountInWords(Math.round(parseFloat(subTotal) + localSgstUgst + localCgst + localIgst));
      }
    }
  }
  const checkAmountInWords = (num) => {
    let a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
    let b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    if ((num = num.toString()).length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return; let str = '';
    str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
    str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
    setAmountInWords(str + "Only");
  }
  const checkSubTotal = (value, id, refrence) => {
    let localBasicFreight = basicFreight;
    let localLoadingCharges = loadingCharges;
    let localCraneCharges = craneCharges;
    let localFovCharges = fovCharges;
    let localFuelSurcharges = fuelSurcharges;
    let localLrCharges = lrCharges;
    if (value !== "" && parseInt(value) > 0) {
      if (id === "basicFreight") {
        localBasicFreight = value;
      } else if (id === "loadingCharges") {
        localLoadingCharges = value;
      } else if (id === "craneCharges") {
        localCraneCharges = value;
      } else if (id === "fovCharges") {
        localFovCharges = value;
      } else if (id === "fuelSurcharges") {
        localFuelSurcharges = value;
      } else if (id === "lrCharges") {
        localLrCharges = value;
      }
      let localSubTotal = parseInt(localBasicFreight) + parseInt(localLoadingCharges) + parseInt(localCraneCharges) + parseInt(localFovCharges) + parseInt(localFuelSurcharges) + parseInt(localLrCharges);
      checkDeliveryState(deliveryState, localSubTotal);
      setSubTotal(localSubTotal);
      checkRcm(rcm, localSubTotal);
      let removedZeroes = parseInt(value);
      setTimeout(() => {
        refrence[id](removedZeroes);
      }, 10)
    } else {
      if (id === "basicFreight") {
        localBasicFreight = 0;
      } else if (id === "loadingCharges") {
        localLoadingCharges = 0;
      } else if (id === "craneCharges") {
        localCraneCharges = 0;
      } else if (id === "fovCharges") {
        localFovCharges = 0;
      } else if (id === "fuelSurcharges") {
        localFuelSurcharges = 0;
      } else if (id === "lrCharges") {
        localLrCharges = 0;
      }
      let localSubTotal = parseInt(localBasicFreight) + parseInt(localLoadingCharges) + parseInt(localCraneCharges) + parseInt(localFovCharges) + parseInt(localFuelSurcharges) + parseInt(localLrCharges);
      checkDeliveryState(deliveryState, localSubTotal);
      setSubTotal(localSubTotal);
      checkRcm(rcm, localSubTotal);
      refrence[id]("0");
    }
  }
  const handleTotal = (event) => {
    let refrence = {
      "deliveryState": setDeliveryState,
      "basicFreight": setBasicFreight,
      "loadingCharges": setLoadingCharges,
      "craneCharges": setCraneCharges,
      "fovCharges": setFovCharges,
      "fuelSurcharges": setFuelSurcharges,
      "lrCharges": setLrCharges,
      "subTotal": setSubTotal,
      "rcm": setRcm,
      "grandTotal": setGrandTotal,
      "amountInWords": setAmountInWords
    };
    if (event.target.id === "deliveryState") {
      checkDeliveryState(event.target.value, subTotal);
      refrence[event.target.id](event.target.value);
    } else if (event.target.id === "rcm") {
      checkRcm(event.target.value, subTotal);
      refrence[event.target.id](event.target.value);
    } else if (event.target.id !== "subTotal" && event.target.id !== "grandTotal" && event.target.id !== "amountInWords") {
      checkSubTotal(event.target.value, event.target.id, refrence);
    }
  }
  const handleClientChanged = (event) => {
    if (event.target.id === "consignerName") {
      client.forEach(element => {
        if (element.pk === parseInt(event.target.value)) {
          document.getElementById("consignerAddress").value = element.fields.clientAddress;
          document.getElementById("consignerGstNo").value = element.fields.gstNo;
          document.getElementById("consignerContactNo").value = element.fields.clientContactNo;
        }
      });
    } else {
      client.forEach(element => {
        if (element.pk === parseInt(event.target.value)) {
          document.getElementById("consigneeAddress").value = element.fields.clientAddress;
          document.getElementById("consigneeGstNo").value = element.fields.gstNo;
          document.getElementById("consigneeContactNo").value = element.fields.clientContactNo;
        }
      });
    }
  }
  const validateGcn = () => {
    if (document.getElementById("gcnDate").value === "") {
      authenticate.setMessage({message :"Gcn Date Cannot Be Blank"});
      return false;
    } else if (document.getElementById("truckNo").value === "") {
      authenticate.setMessage({message :"Truck Number Cannot Be Blank"});
      return false;
    } else if (document.getElementById("pickupLocation").value === "select") {
      authenticate.setMessage({message :"Pickup Location Cannot Be Blank"});
      return false;
    } else if (document.getElementById("deliveryLocation").value === "select") {
      authenticate.setMessage({message :"Delivery Location Cannot Be Blank"});
      return false;
    } else if (deliveryState === "select") {
      authenticate.setMessage({message :"Delivery State Cannot Be Blank"});
      return false;
    } else if (document.getElementById("deliveryType").value === "select") {
      authenticate.setMessage({message :"Delivery Type Cannot Be Blank"});
      return false;
    } else if (document.getElementById("freightType").value === "select") {
      authenticate.setMessage({message :"Freight Type Cannot Be Blank"});
      return false;
    } else if (document.getElementById("billTo").value === "select") {
      authenticate.setMessage({message :"Bill To Cannot Be Blank"});
      return false;
    } else if (document.getElementById("consignerName").value === "select") {
      authenticate.setMessage({message :"Consigner Name Cannot Be Blank"});
      return false;
    } else if (document.getElementById("consigneeName").value === "select") {
      authenticate.setMessage({message :"Consignee Name Cannot Be Blank"});
      return false;
    } else if (document.getElementById("invoiceNo").value === "") {
      authenticate.setMessage({message :"Invoice Number Cannot Be Blank"});
      return false;
    } else if (document.getElementById("invoiceDate").value === "") {
      authenticate.setMessage({message :"Invoice Date Cannot Be Blank"});
      return false;
    } else if (document.getElementById("invoiceValue").value === "") {
      authenticate.setMessage({message :"Invoice Value Cannot Be Blank"});
      return false;
    }
    return true;
  }
  const handleSubmit = () => {
    if (validateGcn()) {
      const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
      let gcnData = {
        "gcnNo": gcnNumber,
        "gcnDate": document.getElementById("gcnDate").value,
        "truckNo": document.getElementById("truckNo").value,
        "ewayBillNo": document.getElementById("ewaybillNo").value === "" ? null : document.getElementById("ewaybillNo").value,
        "ewayBillExpiryDate": document.getElementById("ewaybillExpDate").value === "" ? null : document.getElementById("ewaybillExpDate").value,
        "pickupState": document.getElementById("pickupState").value === "select" ? null : parseInt(document.getElementById("pickupState").value),
        "pickupLocation": parseInt(document.getElementById("pickupLocation").value),
        "deliveryState": parseInt(deliveryState),
        "deliveryLocation": parseInt(document.getElementById("deliveryLocation").value),
        "riskType": document.getElementById("riskType").value,
        "pickupType": document.getElementById("pickupType").value === "select" ? null : document.getElementById("pickupType").value,
        "deliveryType": document.getElementById("deliveryType").value,
        "freightType": document.getElementById("freightType").value,
        "billTo": document.getElementById("billTo").value,
        "consigner": parseInt(document.getElementById("consignerName").value),
        "consignee": parseInt(document.getElementById("consigneeName").value),
        "invoiceNo": document.getElementById("invoiceNo").value,
        "invoiceDate": document.getElementById("invoiceDate").value,
        "invoiceValue": document.getElementById("invoiceValue").value,
        "pkgAsPerInvoice": document.getElementById("pkgAsPerInvoice").value === "" ? null : parseInt(document.getElementById("pkgAsPerInvoice").value),
        "actualPakage": document.getElementById("actualPkg").value === "" ? null : parseInt(document.getElementById("actualPkg").value),
        "methodOfPackage": document.getElementById("methodOfPkg").value === "select" ? null : parseInt(document.getElementById("methodOfPkg").value),
        "description": document.getElementById("description").value === "select" ? null : parseInt(document.getElementById("description").value),
        "actualWeight": document.getElementById("actualWeight").value === "" ? null : document.getElementById("actualWeight").value,
        "chargedWeight": document.getElementById("chargedWeight").value === "" ? null : document.getElementById("chargedWeight").value,
        "specialRemark": document.getElementById("specialRemark").value === "" ? null : document.getElementById("specialRemark").value,
        "basicFreight": basicFreight,
        "loadingCharges": loadingCharges,
        "craneCharges": craneCharges,
        "fovCharges": fovCharges,
        "fuelSurCharges": fuelSurcharges,
        "lrCharges": lrCharges,
        "subTotal": subTotal,
        "sgstUtgst": sgstUtgst,
        "cgst": cgst,
        "igst": igst,
        "rcm": rcm,
        "grandTotal": grandTotal,
        "amountInWords": amountInWords,
        "remark": document.getElementById("remark").value === "" ? null : document.getElementById("remark").value,
        "driverNumber": document.getElementById("driverMobileNo").value === "" ? null : parseInt(document.getElementById("driverMobileNo").value),
        "deliveryStatus": document.getElementById("deliveryStatus").checked,
        "deliveryDate": document.getElementById("deliveryDate").value === "" ? null : document.getElementById("deliveryDate").value
      }
      if (!props.edit) {
        const addGcnUrl = `${authenticate.apiUrl}/api/gcn/`;
        fetch(addGcnUrl, {
          method: "POST",
          headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
          body: JSON.stringify(gcnData)
        }).then(response => {
          if (response.status === 200) {
            authenticate.setMessage({message:"Gcn Submitted Successfully", navigateHome:true});
          } else {
            authenticate.setMessage({message:response.statusText});
          }
        });
      } else {
        const updateGcnUrl = `${authenticate.apiUrl}/api/gcn/`;
        fetch(updateGcnUrl, {
          method: "PUT",
          headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
          body: JSON.stringify(gcnData)
        }).then(response => {
          if (response.status === 200) {
            authenticate.setMessage({message:"Gcn Updated Successfully", navigateHome:true});
          } else {
            authenticate.setMessage({message:response.statusText});
          }
        });
      }
    }
  }
  const handleEwaybillno = (event) => {
    let ewaybillNo = event.target;
    let ewaybillExpDate = document.getElementById('ewaybillExpDate');
    let truckNo = document.getElementById('truckNo');
    let pickupState = document.getElementById("pickupState");
    let consignerName = document.getElementById("consignerName");
    let consigneeName = document.getElementById("consigneeName");
    let invoiceNo = document.getElementById('invoiceNo');
    let invoiceDate = document.getElementById('invoiceDate');
    let invoiceValue = document.getElementById('invoiceValue');
    let actualWeight = document.getElementById('actualWeight');
    let matchCountConsigner = [];
    let matchCountConsignee = [];
    let stateCodes = {
      "2": "15",
      "3": "21",
      "5": "26",
      "6": "14",
      "7": "28",
      "8": "22",
      "9": "25",
      "10": "12",
      "11": "23",
      "12": "10",
      "13": "20",
      "14": "18",
      "15": "19",
      "16": "24",
      "17": "27",
      "18": "11",
      "19": "29",
      "20": "16",
      "21": "9",
      "22": "13",
      "23": "5",
      "24": "2",
      "27": "1",
      "29": "4",
      "30": "6",
      "32": "17",
      "33": "3",
      "36": "7",
      "37": "8",
    };
    let ewaybillModified = ewaybillNo.value;
    const reg = /[\s*\,*\/*\\*]+/g;
    let ewabillSeparated = ewaybillModified.split(reg);
    if (ewabillSeparated.length === 1) {
      ewabillSeparated.forEach(element => {
        if (element.length === 12) {
          const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
          const gcnNumbersUrl = `${authenticate.apiUrl}/api/ewaybill/?ewaybillNo=${element}`;
          fetch(gcnNumbersUrl, {
            method: "GET",
            headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
          }).then(response => {
            if (response.status === 200) {
              return response.json();
            } else {
              // setErrorMsg(response.statusText);
            }
          }).then(data => {
            let parsedData = JSON.parse(data);
            if (parsedData['status_cd'] === "1") {
              let expStringDate = parsedData['data']['validUpto'];
              let invDate = parsedData['data']['docDate'];
              ewaybillExpDate.value = `${expStringDate.slice(6, 10)}-${expStringDate.slice(3, 5)}-${expStringDate.slice(0, 2)}`;
              truckNo.value = parsedData['data']['VehiclListDetails'][0]['vehicleNo'];
              pickupState.value = stateCodes[parsedData['data']['fromStateCode']];
              setDeliveryState(stateCodes[parsedData['data']['toStateCode']]);
              invoiceNo.value = parsedData['data']['docNo'];
              invoiceDate.value = `${invDate.slice(6, 10)}-${invDate.slice(3, 5)}-${invDate.slice(0, 2)}`;
              invoiceValue.value = Math.ceil(parsedData['data']['totInvValue']);
              let actWeight = 0;
              parsedData['data']['itemList'].forEach(element => {
                actWeight += element['quantity'];
              });
              actualWeight.value = actWeight;
              let ewaybillConsigner = parsedData['data']['fromTrdName'].replace(" ", "").toUpperCase().slice(0, 10);
              let ewaybillConsignee = parsedData['data']['toTrdName'].replace(" ", "").toUpperCase().slice(0, 10);
              let clientMatch = [[consignerName, matchCountConsigner, ewaybillConsigner], [consigneeName, matchCountConsignee, ewaybillConsignee]];
              clientMatch.forEach(clientLocal => {
                clientLocal[1] = [];
                Array.from(clientLocal[0].options).forEach(cl => {
                  let dbCl = cl.innerText.replace(" ", "").toUpperCase().slice(0, 10);
                  let ewayBillCl = clientLocal[2]
                  if (dbCl.toLowerCase() === ewayBillCl.toLowerCase()) {
                    clientLocal[1].push(cl.value);
                  }
                });
                if (clientLocal[1].length = 1) {
                  clientLocal[0].value = clientLocal[1][0];
                  client.forEach(element => {
                    if (element.pk === parseInt(clientLocal[0].value)) {
                      if (clientLocal[0].id === "consignerName") {
                        document.getElementById("consignerAddress").value = element.fields.clientAddress;
                        document.getElementById("consignerGstNo").value = element.fields.gstNo;
                        document.getElementById("consignerContactNo").value = element.fields.clientContactNo;
                      } else {
                        document.getElementById("consigneeAddress").value = element.fields.clientAddress;
                        document.getElementById("consigneeGstNo").value = element.fields.gstNo;
                        document.getElementById("consigneeContactNo").value = element.fields.clientContactNo;
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });
    } else {
      let invValue = 0.0;
      let actTotalWeight = 0;
      ewabillSeparated.forEach((element, index) => {
        if (element.length === 12) {
          const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
          const gcnNumbersUrl = `${authenticate.apiUrl}/api/ewaybill/?ewaybillNo=${element}`;
          fetch(gcnNumbersUrl, {
            method: "GET",
            headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
          }).then(response => {
            if (response.status === 200) {
              return response.json();
            } else {
              // setErrorMsg(response.statusText);
            }
          }).then(data => {
            let parsedData = JSON.parse(data);
            if (parsedData['status_cd'] === "1") {
              if (index == 0) {
                let expStringDate = parsedData['data']['validUpto'];
                let invDate = parsedData['data']['docDate'];
                ewaybillExpDate.value = `${expStringDate.slice(6, 10)}-${expStringDate.slice(3, 5)}-${expStringDate.slice(0, 2)}`;
                truckNo.value = parsedData['data']['VehiclListDetails'][0]['vehicleNo'];
                pickupState.value = stateCodes[parsedData['data']['fromStateCode']];
                setDeliveryState(stateCodes[parsedData['data']['toStateCode']]);
                invoiceDate.value = `${invDate.slice(6, 10)}-${invDate.slice(3, 5)}-${invDate.slice(0, 2)}`;
                invoiceNo.value = parsedData['data']['docNo'];
                invValue += parseFloat(parsedData['data']['totInvValue']);
                let actWeight = 0;
                parsedData['data']['itemList'].forEach(element => {
                  actWeight += element['quantity'];
                });
                actTotalWeight += actWeight;
              }
              else {
                invoiceNo.value += ` ${parsedData['data']['docNo']}`;
                invValue += parseFloat(parsedData['data']['totInvValue']);
                let actWeight = 0;
                parsedData['data']['itemList'].forEach(element => {
                  actWeight += element['quantity'];
                });
                actTotalWeight += actWeight;
                invoiceValue.value = Math.ceil(invValue);
                actualWeight.value = actTotalWeight;
              }
            }
          });
        }
      });
    }
  }
  useEffect(() => {
    if (!authenticate.isLogin) {
      navigate("/");
    } else {
      authenticate.handleProgress(10);
      const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
      const stateUrl = `${authenticate.apiUrl}/api/state/`;
      fetch(stateUrl, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
      }).then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          // setErrorMsg(response.statusText);
        }
      }).then(data => {
        let parsedData = JSON.parse(data);
        setState(parsedData);
      });
      authenticate.handleProgress(30);
      const locationUrl = `${authenticate.apiUrl}/api/location/`;
      fetch(locationUrl, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
      }).then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          // setErrorMsg(response.statusText);
        }
      }).then(data => {
        let parsedData = JSON.parse(data);
        setLocation(parsedData);
      });
      authenticate.handleProgress(50);
      const clientUrl = `${authenticate.apiUrl}/api/client/`;
      fetch(clientUrl, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
      }).then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          // setErrorMsg(response.statusText);
        }
      }).then(data => {
        let parsedData = JSON.parse(data);
        setClient(parsedData);
      });
      authenticate.handleProgress(70);
      const descriptionUrl = `${authenticate.apiUrl}/api/description/`;
      fetch(descriptionUrl, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
      }).then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          // setErrorMsg(response.statusText);
        }
      }).then(data => {
        let parsedData = JSON.parse(data);
        setDescription(parsedData);
      });
      authenticate.handleProgress(90);
      const packageTypeUrl = `${authenticate.apiUrl}/api/packagetype/`;
      fetch(packageTypeUrl, {
        method: "GET",
        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
      }).then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          // setErrorMsg(response.statusText);
        }
      }).then(data => {
        let parsedData = JSON.parse(data);
        setPackageType(parsedData);
      });
      authenticate.handleProgress(100);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (!authenticate.isLogin) {
      navigate("/");
    } else {
      const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
      if (!props.edit) {
        document.title = "SSTransline | Gcn Entry";
        const gcnNumbersUrl = `${authenticate.apiUrl}/api/gcn/?allNumbersOnly=True`;
        fetch(gcnNumbersUrl, {
          method: "GET",
          headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
        }).then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            // setErrorMsg(response.statusText);
          }
        }).then(data => {
          let parsedData = JSON.parse(data);
          let latestGcnNumber = `S-${parsedData.latest_gcn + 1}`;
          setGcnNumber(latestGcnNumber);
          document.getElementById("gcnDate").value = "";
          document.getElementById("truckNo").value = "";
          document.getElementById("ewaybillNo").value = "";
          document.getElementById("ewaybillExpDate").value = "";
          document.getElementById("pickupType").value = "select";
          document.getElementById("deliveryType").value = "select";
          document.getElementById("freightType").value = "select";
          document.getElementById("billTo").value = "select";
          document.getElementById("invoiceNo").value = "";
          document.getElementById("invoiceDate").value = "";
          document.getElementById("invoiceValue").value = "";
          document.getElementById("pkgAsPerInvoice").value = "";
          document.getElementById("actualPkg").value = "";
          document.getElementById("actualWeight").value = "";
          document.getElementById("chargedWeight").value = "";
          document.getElementById("specialRemark").value = "";
          setBasicFreight(0);
          setLoadingCharges(0);
          setCraneCharges(0);
          setFovCharges(0);
          setFuelSurcharges(0);
          setLrCharges(0);
          setSubTotal(0);
          setSgstUtgst(0);
          setCgst(0);
          setIgst(0);
          setRcm("Yes");
          setGrandTotal(0);
          setAmountInWords(0);
          document.getElementById("remark").value = "";
          document.getElementById("driverMobileNo").value = "";
          document.getElementById("deliveryStatus").checked = false;
          document.getElementById("deliveryDate").value = "";
          document.getElementById("pickupState").value = "select";
          setDeliveryState("select");
          document.getElementById("pickupLocation").value = "select";
          document.getElementById("deliveryLocation").value = "select";
          document.getElementById("consignerName").value = "select";
          document.getElementById("consignerAddress").value = "";
          document.getElementById("consignerGstNo").value = "";
          document.getElementById("consignerContactNo").value = "";
          document.getElementById("consigneeName").value = "select";
          document.getElementById("consigneeAddress").value = "";
          document.getElementById("consigneeGstNo").value = "";
          document.getElementById("consigneeContactNo").value = "";
          document.getElementById("description").value = "select";
          document.getElementById("methodOfPkg").value = "select";
        });
      } else {
        document.title = "SSTransline | Gcn Modify";
        const gcnNumbersUrl = `${authenticate.apiUrl}/api/gcn/?gcnNo=${pageData.gcnNo}`;
        fetch(gcnNumbersUrl, {
          method: "GET",
          headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
        }).then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            // setErrorMsg(response.statusText);
          }
        }).then(data => {
          let parsedData = JSON.parse(data);
          setSelectedGcnData(parsedData);
          setGcnNumber(parsedData[0].pk);
          document.getElementById("gcnDate").value = parsedData[0].fields.gcnDate;
          document.getElementById("truckNo").value = parsedData[0].fields.truckNo;
          document.getElementById("ewaybillNo").value = parsedData[0].fields.ewayBillNo === null ? "" : parsedData[0].fields.ewayBillNo;
          document.getElementById("ewaybillExpDate").value = parsedData[0].fields.ewayBillExpiryDate === null ? "" : parsedData[0].fields.ewayBillExpiryDate;
          document.getElementById("pickupType").value = parsedData[0].fields.pickupType === null ? "select" : parsedData[0].fields.pickupType;
          document.getElementById("deliveryType").value = parsedData[0].fields.deliveryType;
          document.getElementById("freightType").value = parsedData[0].fields.freightType;
          document.getElementById("billTo").value = parsedData[0].fields.billTo;
          document.getElementById("invoiceNo").value = parsedData[0].fields.invoiceNo;
          document.getElementById("invoiceDate").value = parsedData[0].fields.invoiceDate;
          document.getElementById("invoiceValue").value = parsedData[0].fields.invoiceValue;
          document.getElementById("pkgAsPerInvoice").value = parsedData[0].fields.pkgAsPerInvoice === null ? "" : parsedData[0].fields.pkgAsPerInvoice;
          document.getElementById("actualPkg").value = parsedData[0].fields.actualPakage === null ? "" : parsedData[0].fields.actualPakage;
          document.getElementById("actualWeight").value = parsedData[0].fields.actualWeight === null ? "" : parsedData[0].fields.actualWeight;
          document.getElementById("chargedWeight").value = parsedData[0].fields.chargedWeight === null ? "" : parsedData[0].fields.chargedWeight;
          document.getElementById("specialRemark").value = parsedData[0].fields.specialRemark === null ? "" : parsedData[0].fields.specialRemark;
          setBasicFreight(parsedData[0].fields.basicFreight === null ? 0 : parsedData[0].fields.basicFreight);
          setLoadingCharges(parsedData[0].fields.loadingCharges === null ? 0 : parsedData[0].fields.loadingCharges);
          setCraneCharges(parsedData[0].fields.craneCharges === null ? 0 : parsedData[0].fields.craneCharges);
          setFovCharges(parsedData[0].fields.fovCharges === null ? 0 : parsedData[0].fields.fovCharges);
          setFuelSurcharges(parsedData[0].fields.fuelSurCharges === null ? 0 : parsedData[0].fields.fuelSurCharges);
          setLrCharges(parsedData[0].fields.lrCharges === null ? 0 : parsedData[0].fields.lrCharges);
          setSubTotal(parsedData[0].fields.subTotal === null ? 0 : parsedData[0].fields.subTotal);
          setSgstUtgst(parsedData[0].fields.sgstUtgst === null ? 0 : parsedData[0].fields.sgstUtgst);
          setCgst(parsedData[0].fields.cgst === null ? 0 : parsedData[0].fields.cgst);
          setIgst(parsedData[0].fields.igst === null ? 0 : parsedData[0].fields.igst);
          setRcm(parsedData[0].fields.rcm === null ? 0 : parsedData[0].fields.rcm);
          setGrandTotal(parsedData[0].fields.grandTotal === null ? 0 : parsedData[0].fields.grandTotal);
          setAmountInWords(parsedData[0].fields.amountInWords === null ? "Zero" : parsedData[0].fields.amountInWords);
          document.getElementById("remark").value = parsedData[0].fields.remark === null ? "" : parsedData[0].fields.remark;
          document.getElementById("driverMobileNo").value = parsedData[0].fields.driverNumber === null ? "" : parsedData[0].fields.driverNumber;
          document.getElementById("deliveryStatus").checked = parsedData[0].fields.deliveryStatus;
          document.getElementById("deliveryDate").value = parsedData[0].fields.deliveryDate === null ? "" : parsedData[0].fields.deliveryDate;
        });
      }
    }
  }, [props.edit]);
  useEffect(() => {
    if (!authenticate.isLogin) {
      navigate("/");
    } else {
      if (selectedGcnData.length !== 0) {
        if (state.length !== 0) {
          document.getElementById("pickupState").value = selectedGcnData[0].fields.pickupState === null ? "select" : selectedGcnData[0].fields.pickupState;
          setDeliveryState(selectedGcnData[0].fields.deliveryState);
        }
        if (location.length !== 0) {
          document.getElementById("pickupLocation").value = selectedGcnData[0].fields.pickupLocation;
          document.getElementById("deliveryLocation").value = selectedGcnData[0].fields.deliveryLocation;
        }
        if (client.length !== 0) {
          document.getElementById("consignerName").value = selectedGcnData[0].fields.consigner;
          client.forEach(element => {
            if (element.pk === selectedGcnData[0].fields.consigner) {
              document.getElementById("consignerAddress").value = element.fields.clientAddress;
              document.getElementById("consignerGstNo").value = element.fields.gstNo;
              document.getElementById("consignerContactNo").value = element.fields.clientContactNo;
            }
          });
          document.getElementById("consigneeName").value = selectedGcnData[0].fields.consignee;
          client.forEach(element => {
            if (element.pk === selectedGcnData[0].fields.consignee) {
              document.getElementById("consigneeAddress").value = element.fields.clientAddress;
              document.getElementById("consigneeGstNo").value = element.fields.gstNo;
              document.getElementById("consigneeContactNo").value = element.fields.clientContactNo;
            }
          });
        }
        if (description.length !== 0) {
          document.getElementById("description").value = selectedGcnData[0].fields.description === null ? "select" : selectedGcnData[0].fields.description;
        }
        if (packageType.length !== 0) {
          document.getElementById("methodOfPkg").value = selectedGcnData[0].fields.methodOfPackage === null ? "select" : selectedGcnData[0].fields.methodOfPackage;
        }
      }
    }
  }, [state, client, location, description, packageType, selectedGcnData])
  const items = {
    "Booking Information": {
      "className": "bookingSection",
      "elements": [{ "label": "GCN No", "id": "gcnNo", "type": "text", "value": gcnNumber, "onChange": handleGcnNumber }, { "label": "GCN Date", "id": "gcnDate", "type": "date" }, { "label": "Ewaybill No", "id": "ewaybillNo", "type": "text", "onChange": handleEwaybillno }, { "label": "Ewaybill Exp Date", "id": "ewaybillExpDate", "type": "date" }, { "label": "Truck No", "id": "truckNo", "type": "text" }, { "label": "Pickup State", "id": "pickupState", "type": "select" }, { "label": "Pickup Location", "id": "pickupLocation", "type": "select" }, { "label": "Delivery State", "id": "deliveryState", "type": "select", "value": deliveryState, "onChange": handleTotal }, { "label": "Delivery Location", "id": "deliveryLocation", "type": "select" }, { "label": "Risk Type", "id": "riskType", "type": "text", "placeholder": "Owner's Risk Only", "value": "Owner Risk", "readOnly": true }, { "label": "Pickup type", "id": "pickupType", "type": "select", "options": { "value": ["select", "godown pickup", "door pickup"] } }, { "label": "Delivery type", "id": "deliveryType", "type": "select", "options": { "value": ["select", "godown delivery", "door delivery"] } }, { "label": "Freight type", "id": "freightType", "type": "select", "options": { "value": ["select", "TBB", "To Pay", "Paid"] } }, { "label": "Bill To", "id": "billTo", "type": "select", "options": { "value": ["select", "consigner", "consignee"] } }, { "label": "Driver Mobile Number", "id": "driverMobileNo", "type": "number" }]
    },
    "Invoice Details": {
      "className": "bookingSection",
      "elements": [{ "label": "Invoice No", "id": "invoiceNo", "type": "text" }, { "label": "Invoice Date", "id": "invoiceDate", "type": "date" }, { "label": "Invoice Value", "id": "invoiceValue", "type": "number" }, { "label": "Pkg As Per Invoice", "id": "pkgAsPerInvoice", "type": "number" }, { "label": "Actual Pkg", "id": "actualPkg", "type": "number" }, { "label": "Method of Pkg", "id": "methodOfPkg", "type": "select" }, { "label": "Description", "id": "description", "type": "select" }, { "label": "Actual Weight", "id": "actualWeight", "type": "text" }, { "label": "Charged Weight", "id": "chargedWeight", "type": "text" }, { "label": "Special Remark", "id": "specialRemark", "type": "text" }]
    },
    "Freight Details": {
      "className": "bookingSection",
      "elements": [{ "label": "Basic Freight", "id": "basicFreight", "type": "number", "value": basicFreight, "onChange": handleTotal }, { "label": "Loading Charges", "id": "loadingCharges", "type": "number", "maxLength": "20", "value": loadingCharges, "onChange": handleTotal }, { "label": "Crane Charges", "id": "craneCharges", "type": "number", "maxLength": "20", "value": craneCharges, "onChange": handleTotal }, { "label": "Other Charges", "id": "fovCharges", "type": "number", "maxLength": "20", "value": fovCharges, "onChange": handleTotal }, { "label": "Fuel Surcharges", "id": "fuelSurcharges", "type": "number", "maxLength": "20", "value": fuelSurcharges, "onChange": handleTotal }, { "label": "LR Charges", "id": "lrCharges", "type": "number", "maxLength": "20", "value": lrCharges, "onChange": handleTotal }, { "label": "Sub Total", "id": "subTotal", "type": "number", "maxLength": "20", "value": subTotal, "onChange": handleTotal }, { "label": "SGST/UTGST", "id": "sgstUtgst", "type": "number", "maxLength": "20", "value": sgstUtgst, "onChange": handleTotal }, { "label": "CGST", "id": "cgst", "type": "number", "maxLength": "20", "value": cgst, "onChange": handleTotal }, { "label": "IGST", "id": "igst", "type": "number", "maxLength": "20", "value": igst, "onChange": handleTotal }, { "label": "RCM", "id": "rcm", "type": "select", "options": { "value": ["Yes", "No"] }, "value": rcm, "onChange": handleTotal }, { "label": "Grand Total", "id": "grandTotal", "type": "number", "maxLength": "20", "value": grandTotal, "onChange": handleTotal }, { "label": "Amount In Words", "id": "amountInWords", "type": "text", "divId": "amountInWordsDiv", "value": amountInWords, "onChange": handleTotal }, { "label": "Remark", "id": "remark", "type": "text", "divId": "remarkDiv" }]
    },
    "Bottom Extra Details": {
      "className": "bookingSection",
      "elements": [{ "label": "Delivery Status", "id": "deliveryStatus", "type": "checkbox" }, { "label": "Delivery Date", "id": "deliveryDate", "type": "date" }]
    },
    "Consignor Details": {
      "className": "cnercneeSection",
      "elements": [{ "label": "Consignor Name", "id": "consignerName", "type": "select", "onChange": handleClientChanged }, { "label": "Consignor Address", "id": "consignerAddress", "type": "textArea", "cols": "30", "rows": "2" }, { "label": "Consignor GST No", "id": "consignerGstNo", "type": "text" }, { "label": "Consignor Contact No", "id": "consignerContactNo", "type": "text" }]
    },
    "Consignee Details": {
      "className": "cnercneeSection",
      "elements": [{ "label": "Consignee Name", "id": "consigneeName", "type": "select", "onChange": handleClientChanged }, { "label": "Consignee Address", "id": "consigneeAddress", "type": "textArea", "cols": "30", "rows": "2" }, { "label": "Consignee GST No", "id": "consigneeGstNo", "type": "text" }, { "label": "Consignee Contact No", "id": "consigneeContactNo", "type": "text" }]
    }
  }
  return (
    <>
      {authenticate.isLogin && <div>
        <div className="heading">
          <div>Booking Information</div>
        </div>
        <div className={items["Booking Information"].className}>
          {items["Booking Information"].elements.map(element => {
            return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} states={state} locations={location} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
          })}
        </div>
        <div className="heading">
          <div>Party Details</div>
        </div>
        <div id="partyDetailsSection">
          <div>
            <div className="heading">
              <div>Consignor Details</div>
            </div>
            <div className={items["Consignor Details"].className}>
              {items["Consignor Details"].elements.map(element => {
                return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} clients={client} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
              })}
            </div>
          </div>
          <div>
            <div className="heading">
              <div>Consignee Details</div>
            </div>
            <div className={items["Consignee Details"].className}>
              {items["Consignee Details"].elements.map(element => {
                return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} clients={client} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
              })}
            </div>
          </div>
        </div>
        <div className="heading">
          <div>Invoice Details</div>
        </div>
        <div className={items["Invoice Details"].className}>
          {items["Invoice Details"].elements.map(element => {
            return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} packageTypes={packageType} descriptions={description} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
          })}
        </div>
        <div className="heading">
          <div>Freight Details</div>
        </div>
        <div className={items["Freight Details"].className}>
          {items["Freight Details"].elements.map(element => {
            return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} states={state} /> : <LabelInput key={element.id} id={element.id} value={element.value !== "" ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
          })}
        </div>
        <div id="btnContainer">
          <input type="button" name="SubmitGcn" id="submitGcn" className="navbarButton" value="Submit GCN" onClick={handleSubmit} />
        </div>
        <div className={items["Bottom Extra Details"].className}>
          {items["Bottom Extra Details"].elements.map(element => {
            return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} states={state} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
          })}
        </div>
      </div>}
    </>
  )
}

export default GcnEntry
