import React, { useContext } from 'react';
import '../css/tableUtility.css';
import { Link } from 'react-router-dom';
import SstContext from '../contexts/SstContext';
import Delete from './Delete';
import Edit from './Edit';
import Print from './Print';

const TableUtility = (props) => {
    const authenticate = useContext(SstContext);
    const handleGcnPrint = (gcnNum) => {
        authenticate.setMessage({ message: gcnNum, gcnPrint: true });
    }
    const handleBillPrint = (billNum) => {
        authenticate.setMessage({ message: "Generating Bill Print", billNum: billNum, billPrint: true });
    }
    const handleGcnDelete = (gcnNum) => {
        const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
        const gcnNumbersUrl = `${authenticate.apiUrl}/api/gcn/`;
        const gcnDeleteData = {
            "gcnNo": gcnNum
        }
        if (authenticate.admin) {
            fetch(gcnNumbersUrl, {
                method: "DELETE",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                body: JSON.stringify(gcnDeleteData)
            }).then(response => {
                if (response.status === 200) {
                    authenticate.setMessage({ message: "Gcn Deleted Successfully" });
                    props.setGcnDeleted(props.gcnDeleted + 1)
                } else {
                    authenticate.setMessage({ message: response.statusText });
                }
            });
        } else {
            authenticate.setMessage({ message: "You are not authorized to delete Gcn" });
        }
    }
    const handleThcPrint = (thcNum) => {
        console.log(thcNum);
        // const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
        // const gcnNumbersUrl = `${authenticate.apiUrl}/api/gcn/?gcnNo=${gcnNum}&copies=${checkCopies[0]}${checkCopies[1]}${checkCopies[2]}${checkCopies[3]}${checkCopies[4]}`;
        // fetch(gcnNumbersUrl, {
        //     method: "GET",
        //     headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
        // }).then(response => {
        //     authenticate.handleProgress(50);
        //     return response.blob();
        // }).then(blob => {
        //     authenticate.handleProgress(80);
        //     let _url = window.URL.createObjectURL(blob);
        //     authenticate.handleProgress(100);
        //     window.open(_url, "_blank", "popup=no");
        //     window.URL.revokeObjectURL(_url);
        // });
    }
    const handleThcDelete = (thcNum) => {
        const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
        const thcNumbersUrl = `${authenticate.apiUrl}/api/thc/`;
        const thcDeleteData = {
            "thcNo": thcNum
        }
        if (authenticate.admin) {
            fetch(thcNumbersUrl, {
                method: "DELETE",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                body: JSON.stringify(thcDeleteData)
            }).then(response => {
                if (response.status === 200) {
                    authenticate.setMessage({ message: "Thc Deleted Successfully" });
                    props.setThcDeleted(props.thcDeleted + 1)
                } else {
                    authenticate.setMessage({ message: response.statusText });
                }
            });
        } else {
            authenticate.setMessage({ message: "You are not authorized to delete Thc" });
        }
    }
    return (
        <>
            <div className={props.item.heading.className}>
                {props.item.heading.titles.map(ele => {
                    return <div key={ele}>
                        <div>{ele}</div>
                    </div>
                })}
            </div>
            <div className={props.item.content.className}>
                {props.item.content.gcnNumbers.map((ele, index) => {
                    return <React.Fragment key={index}> <div className="fontSizeColor borderBottom">
                        <div className="ceterItemsVetically">{index + 1}</div>
                    </div>
                        <div className="fontSizeColor borderBottom">
                            <div className="ceterItemsVetically">{ele}</div>
                        </div>
                        {props.item.content.className !== "billResults" ? <div className="borderBottom" onClick={props.item.content.className === "thcResults" ? () => handleThcPrint(ele) : () => handleGcnPrint(ele)} ><Print /></div> : <div className="borderBottom" onClick={() => handleBillPrint(ele)}><Print /></div>}
                        {props.item.content.className !== "billResults" && <>
                            <div className="borderBottom">
                                <Link to={props.item.content.className === "thcResults" ? `/thc/modify/${ele}` : `/gcn/modify/${ele}`}>
                                    <Edit />
                                </Link>
                            </div>
                            <div className="borderBottom" onClick={props.item.content.className === "thcResults" ? () => handleThcDelete(ele) : () => handleGcnDelete(ele)}>
                                <Delete />
                            </div></>}
                    </React.Fragment>
                })}
            </div>
        </>
    )
}

export default TableUtility
