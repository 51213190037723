import React, { useContext, useEffect } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';

const UploadPod = () => {
    const authenticate = useContext(SstContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            } else {
                document.title = "SSTransline | Upload POD";
            }
        }
        // eslint-disable-next-line
    }, []);
    const items = [{ "label": "Gcn No", "id": "gcnNO", "type": "text" }, { "label": "POD", "id": "pod", "type": "file", "accept": ".pdf" }];
    const validateFileUpload = () => {
        if (document.getElementById("gcnNO").value === "") {
            authenticate.setMessage({ message: "Gcn No Cannot Be Blank" });
            return false;
        }
        else if (document.getElementById("pod").files.length === 0) {
            authenticate.setMessage({ message: "Please Select File" });
            return false;
        }
        return true;
    }
    const handleFileUpload = () => {
        if (validateFileUpload()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            let data = new FormData();
            data.append("gcnNo", document.getElementById("gcnNO").value);
            data.append("file", document.getElementById("pod").files[0]);

            const addFileUrl = `${authenticate.apiUrl}/api/pod/`;
            fetch(addFileUrl, {
                method: "POST",
                headers: { 'Auth-Token': authHeader },
                body: data
            }).then(response => {
                if (response.status === 200) {
                    authenticate.setMessage({message:"POD Uploaded Successfully", navigateHome:true});
                } else {
                    authenticate.setMessage({message:response.statusText});
                }
            });
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="uploadPodSection">
                <div className="heading">
                    <div>Upload POD</div>
                </div>
                <div className="uploadSection">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} accept={element.accept ? element.accept : false} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="submit" id="uploadPodBtn" className="navbarButton" value="Upload" onClick={handleFileUpload}/>
                </div>
            </section>}
        </>
    )
}

export default UploadPod
