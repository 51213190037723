import React, { useState, useEffect, useContext } from 'react';
import { jsPDF } from "jspdf";
import { calibriRegular, calibriBold } from "../fonts/Calibri";
import { sstSymbol, sstLogoBlack, signature, sstLogo } from '../images/SstSymbol';
import '../css/messageBox.css';
import Spinner from './Spinner';
import { useNavigate } from 'react-router-dom';
import { utils, writeFileXLSX } from 'xlsx';
import SstContext from '../contexts/SstContext';

const MessageBox = (props) => {
    const authenticate = useContext(SstContext);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleGcnPrint = () => {
        setLoading(true);
        let driverCopy = document.getElementById('driverCopy');
        let consigneeCopy = document.getElementById('consigneeCopy');
        let consignerCopy = document.getElementById('consignerCopy');
        let billingCopy = document.getElementById('billingCopy');
        let fileCopy = document.getElementById('fileCopy');
        let copies = [];
        if (driverCopy.checked) {
            copies.push("Driver Copy");
        }
        if (consigneeCopy.checked) {
            copies.push("Consignee Copy");
        }
        if (consignerCopy.checked) {
            copies.push("Consignor Copy");
        }
        if (billingCopy.checked) {
            copies.push("Billing Copy");
        }
        if (fileCopy.checked) {
            copies.push("File Copy");
        }
        if (copies.length > 0) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const locationUrl = `${authenticate.apiUrl}/api/location/`;
            fetch(locationUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(locationResponse => {
                if (locationResponse.status === 200) {
                    return locationResponse.json();
                } else {
                    // setErrorMsg(response.statusText);
                }
            }).then(locationData => {
                let parsedLocationData = JSON.parse(locationData);
                const clientUrl = `${authenticate.apiUrl}/api/client/`;
                fetch(clientUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(clientResponse => {
                    if (clientResponse.status === 200) {
                        return clientResponse.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(clientData => {
                    let parsedClientData = JSON.parse(clientData);
                    const descriptionUrl = `${authenticate.apiUrl}/api/description/`;
                    fetch(descriptionUrl, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                    }).then(descriptionResponse => {
                        if (descriptionResponse.status === 200) {
                            return descriptionResponse.json();
                        } else {
                            // setErrorMsg(response.statusText);
                        }
                    }).then(descriptionData => {
                        let parsedDescriptionData = JSON.parse(descriptionData);
                        const packageTypeUrl = `${authenticate.apiUrl}/api/packagetype/`;
                        fetch(packageTypeUrl, {
                            method: "GET",
                            headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                        }).then(packageTypeResponse => {
                            if (packageTypeResponse.status === 200) {
                                return packageTypeResponse.json();
                            } else {
                                // setErrorMsg(response.statusText);
                            }
                        }).then(packageTypeData => {
                            let parsedPackageTypeData = JSON.parse(packageTypeData);
                            const gcnNumberUrl = `${authenticate.apiUrl}/api/gcn/?gcnNo=${props.message.message}`;
                            fetch(gcnNumberUrl, {
                                method: "GET",
                                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                            }).then(response => {
                                if (response.status === 200) {
                                    return response.json();
                                } else {
                                    // setErrorMsg(response.statusText);
                                }
                            }).then(data => {
                                let parsedData = JSON.parse(data);
                                const doc = new jsPDF({ unit: "cm" });
                                copies.forEach((element, index) => {
                                    doc.setDrawColor(127, 127, 127);
                                    doc.setLineWidth(0.01);
                                    doc.rect(1, 1.4, 9.5, 3.4);
                                    doc.rect(10.5, 1.4, 2.375, 1.133);
                                    doc.rect(12.875, 1.4, 2.375, 1.133);
                                    doc.rect(15.25, 1.4, 4.75, 1.133);
                                    doc.rect(10.5, 2.533, 2.375, 1.133);
                                    doc.rect(12.875, 2.533, 2.375, 1.133);
                                    doc.rect(15.25, 2.533, 2.375, 1.133);
                                    doc.rect(17.625, 2.533, 2.375, 1.133);
                                    doc.rect(10.5, 3.666, 2.375, 1.133);
                                    doc.rect(12.875, 3.666, 2.375, 1.133);
                                    doc.rect(15.25, 3.666, 2.375, 1.133);
                                    doc.rect(17.625, 3.666, 2.375, 1.133);
                                    doc.rect(1, 4.8, 9.5, 3.4);
                                    doc.rect(10.5, 4.8, 2.375, 1.133);
                                    doc.rect(12.875, 4.8, 2.375, 1.133);
                                    doc.rect(15.25, 4.8, 4.75, 1.133);
                                    doc.rect(10.5, 5.933, 2.375, 1.133);
                                    doc.rect(12.875, 5.933, 2.375, 1.133);
                                    doc.rect(15.25, 5.933, 2.375, 1.133);
                                    doc.rect(17.625, 5.933, 2.375, 1.133);
                                    doc.rect(10.5, 7.066, 2.375, 1.133);
                                    doc.rect(12.875, 7.066, 2.375, 1.133);
                                    doc.rect(15.25, 7.066, 2.375, 1.133);
                                    doc.rect(17.625, 7.066, 2.375, 1.133);
                                    doc.rect(1, 8.2, 9.5, 3.4);
                                    doc.rect(10.5, 8.2, 2.375, 1.133);
                                    doc.rect(12.875, 8.2, 2.375, 1.133);
                                    doc.rect(15.25, 8.2, 2.375, 1.133);
                                    doc.rect(17.625, 8.2, 2.375, 1.133);
                                    doc.rect(10.5, 9.333, 2.375, 1.133);
                                    doc.rect(12.875, 9.333, 2.375, 1.133);
                                    doc.rect(15.25, 9.333, 2.375, 1.133);
                                    doc.rect(17.625, 9.333, 2.375, 1.133);
                                    doc.rect(10.5, 10.466, 2.375, 1.133);
                                    doc.rect(12.875, 10.466, 2.375, 1.133);
                                    doc.rect(15.25, 10.466, 2.375, 1.133);
                                    doc.rect(17.625, 10.466, 2.375, 1.133);
                                    doc.rect(1, 11.6, 4.75, 2.266);
                                    doc.rect(5.75, 11.6, 4.75, 1.133);
                                    doc.rect(10.5, 11.6, 2.375, 1.133);
                                    doc.rect(12.875, 11.6, 2.375, 1.133);
                                    doc.rect(15.25, 11.6, 2.375, 1.133);
                                    doc.rect(17.625, 11.6, 2.375, 1.133);
                                    doc.rect(5.75, 12.733, 4.75, 1.133);
                                    doc.rect(10.5, 12.733, 2.375, 1.133);
                                    doc.rect(12.875, 12.733, 2.375, 1.133);
                                    doc.rect(15.25, 12.733, 2.375, 1.133);
                                    doc.rect(17.625, 12.733, 2.375, 1.133);
                                    doc.rect(1, 13.866, 14.25, 1.133);
                                    doc.rect(15.25, 13.866, 2.375, 1.133);
                                    doc.rect(17.625, 13.866, 2.375, 1.133);
                                    doc.rect(1, 14.999, 14.25, 1.133);
                                    doc.rect(1, 16.132, 14.25, 1.133);
                                    doc.rect(1, 17.265, 14.25, 2.8);
                                    doc.rect(15.25, 14.999, 4.75, 5.066);
                                    doc.rect(1, 20.065, 19, 5.6);
                                    doc.rect(1, 25.665, 19, 3.1);
                                    doc.addImage(sstSymbol, "webp", 1.1, 1.5, 2, 1, "Symbol");
                                    doc.addImage(sstLogoBlack, "png", 3.2, 1.5, 6.65, 1, "Logo");

                                    doc.addFileToVFS("Calibri-Bold.ttf", calibriBold);
                                    doc.addFont("Calibri-Bold.ttf", "Calibrib", "normal");
                                    doc.addFileToVFS("Calibri-Regular.ttf", calibriRegular);
                                    doc.addFont("Calibri-Regular.ttf", "Calibri", "normal");

                                    doc.setFont("Calibrib");
                                    doc.setFontSize(9);
                                    doc.text("Goods Consignement Note", 16.25, 1.3);
                                    doc.setFont("Calibri");
                                    doc.setFontSize(7);
                                    doc.text("At/Post Uruli Kanchan,Pune-Solapur Road,Tal-Haveli,Dist-Pune,412202", 1.1, 2.96);
                                    doc.text("PAN No :- AHHPD8257G", 1.1, 3.36);
                                    doc.text("GSTIN No :- 27AHHPD8257G2Z7", 1.1, 3.76);
                                    doc.text("Contact No :- 9422331609 / 9689388938", 1.1, 4.16);
                                    doc.text("Mail ID :- sstransline99@gmail.com, Website : www.sstransline.com", 1.1, 4.56);
                                    doc.setFont("Calibrib");
                                    doc.setFontSize(10);
                                    doc.text("GCN No", 10.7, 2.1);
                                    doc.text(parsedData[0].pk, 13.075, 2.1);
                                    doc.text(element, 17.625, 2.1, "center");
                                    doc.text("GCN Date", 10.7, 3.233);
                                    let gcnDate = new Date(parsedData[0].fields.gcnDate);
                                    doc.text(`${gcnDate.getDate()}-${gcnDate.getMonth() + 1}-${gcnDate.getFullYear()}`, 13.075, 3.233);
                                    doc.text("From", 15.45, 3.233);
                                    parsedLocationData.forEach(ele => {
                                        if (ele.pk === parsedData[0].fields.pickupLocation) {
                                            doc.text(ele.fields.location, 17.825, 3.233);
                                        }
                                        if (ele.pk === parsedData[0].fields.deliveryLocation) {
                                            doc.text(ele.fields.location, 17.825, 4.366);
                                        }
                                    });
                                    doc.text("Truck No", 10.7, 4.366);
                                    doc.text(parsedData[0].fields.truckNo.replace(" ", ""), 13.075, 4.366);
                                    doc.text("To", 15.45, 4.366);
                                    parsedClientData.forEach(ele => {
                                        if (ele.pk === parsedData[0].fields.consigner) {
                                            doc.text(`Consignor Name : ${ele.fields.clientName}`, 1.2, 5.5);
                                            doc.setFont("Calibri");
                                            doc.text(`Address : ${ele.fields.clientAddress}`, 1.2, 6, { maxWidth: 9 });
                                            doc.setFont("Calibrib");
                                            doc.text(`GST No : ${ele.fields.gstNo}`, 1.2, 7.2);
                                            doc.text(`Contact No : ${ele.fields.clientContactNo}`, 1.2, 7.7);
                                        }
                                        if (ele.pk === parsedData[0].fields.consignee) {
                                            doc.text(`Consignee Name : ${ele.fields.clientName}`, 1.2, 8.9);
                                            doc.setFont("Calibri");
                                            doc.text(`Address : ${ele.fields.clientAddress}`, 1.2, 9.4, { maxWidth: 9 });
                                            doc.setFont("Calibrib");
                                            doc.text(`GST No : ${ele.fields.gstNo}`, 1.2, 10.6);
                                            doc.text(`Contact No : ${ele.fields.clientContactNo}`, 1.2, 11.1);
                                        }
                                    });
                                    doc.text("Invoice No", 10.7, 5.5);
                                    let splitedInvoiceNo = parsedData[0].fields.invoiceNo.split(/[\s\,]/);
                                    if (splitedInvoiceNo.length > 1) {
                                        doc.text(splitedInvoiceNo, 13.075, 5.1);
                                    } else {
                                        doc.text(parsedData[0].fields.invoiceNo, 13.075, 5.5);
                                    }
                                    doc.text(`Freight Type : ${parsedData[0].fields.freightType}`, 17.625, 5.5, "center");
                                    doc.text("Invoice Date", 10.7, 6.633);
                                    let invoiceDate = new Date(parsedData[0].fields.invoiceDate);
                                    doc.text(`${invoiceDate.getDate()}-${invoiceDate.getMonth() + 1}-${invoiceDate.getFullYear()}`, 13.075, 6.633);
                                    doc.text("Basic Freight", 15.45, 6.633);
                                    doc.text("Invoice Value", 10.7, 7.766);
                                    doc.text(parsedData[0].fields.invoiceValue, 13.075, 7.766);
                                    doc.text("Hamali", 15.45, 7.766);
                                    doc.text("No of PKG", 10.7, 8.9);
                                    doc.text(`${parsedData[0].fields.actualPakage}`, 13.075, 8.9);
                                    doc.text("LR Charges", 15.45, 8.9);
                                    doc.text("PKG Type", 10.7, 10.033);
                                    parsedPackageTypeData.forEach(ele => {
                                        if (ele.pk === parsedData[0].fields.methodOfPackage) {
                                            doc.text(ele.fields.package, 13.075, 10.033);
                                        }
                                    });
                                    doc.text("Sub Total", 15.45, 10.033);
                                    doc.text("Actual WT", 10.7, 11.166);
                                    doc.text(parsedData[0].fields.actualWeight, 13.075, 11.166);
                                    doc.text("SGST/UTGST", 15.45, 11.166);
                                    doc.text("E Way Bill No :", 3.375, 12.3, "center");
                                    if (parsedData[0].fields.ewayBillNo !== null) {
                                        let splitedEwaybillNo = parsedData[0].fields.ewayBillNo.split(/[\s\,]/);
                                        if (splitedEwaybillNo.length > 1) {
                                            doc.text(splitedEwaybillNo, 3.375, 12.7, "center");
                                        } else {
                                            doc.text(parsedData[0].fields.ewayBillNo, 3.375, 12.7, "center");
                                        }
                                    }
                                    doc.text("Description", 8.125, 12.3, "center");
                                    parsedDescriptionData.forEach(ele => {
                                        if (ele.pk === parsedData[0].fields.description) {
                                            doc.text(ele.fields.product, 8.125, 13.433, "center");
                                        }
                                    });
                                    doc.text("Charged WT", 10.7, 12.3);
                                    doc.text(parsedData[0].fields.chargedWeight, 13.075, 12.3);
                                    doc.text("CGST", 15.45, 12.3);
                                    doc.text("Risk Type", 10.7, 13.433);
                                    doc.text(parsedData[0].fields.riskType, 13.075, 13.433);
                                    doc.text("IGST", 15.45, 13.433);
                                    doc.text("Grand Total", 15.45, 14.566);
                                    doc.text(`Special remark : ${parsedData[0].fields.specialRemark === null ? "" : parsedData[0].fields.specialRemark}`, 1.2, 15.699);
                                    doc.text("For S.S.Transline", 15.45, 15.699);
                                    doc.text(`Remark : ${parsedData[0].fields.remark === null ? "" : parsedData[0].fields.remark}`, 1.2, 16.832);
                                    doc.addImage(signature, "png", 16.575, 16.132, 2.5, 2.5, "Signature");
                                    doc.text("Prepared By Sign", 19.7, 19.565, "right");
                                    doc.text("RTGS Details : S S Transline", 1.2, 17.965);
                                    doc.text("Bank Name : HDFC Bank LTD, Uruli Kanchan Pune", 1.2, 18.365);
                                    doc.text("AC No : 50200004782980 IFSC : HDFC0002988", 1.2, 18.765);
                                    doc.text("Mobile No : 9422331609", 1.2, 19.165);
                                    doc.text("PAN No : AHHPD8257G", 1.2, 19.565);
                                    doc.text("C/nee Sign Stamp & Comments :", 1.2, 20.765);
                                    doc.text("Notes :", 1.2, 26.365);
                                    if (parsedData[0].fields.freightType === "TBB") {
                                        doc.text("TBB", 17.825, 14.566);
                                        parsedClientData.forEach(ele => {
                                            if (parsedData[0].fields.billTo === "consigner") {
                                                if (ele.pk === parsedData[0].fields.consigner) {
                                                    doc.text(`Amount In Words : TBB at ${ele.fields.clientName}`, 1.2, 14.566);
                                                }
                                            } else {
                                                if (ele.pk === parsedData[0].fields.consignee) {
                                                    doc.text(`Amount In Words : TBB at ${ele.fields.clientName}`, 1.2, 14.566);
                                                }
                                            }
                                        });
                                    } else {
                                        doc.text(`${parsedData[0].fields.basicFreight}`, 17.825, 6.633);
                                        doc.text(`${parsedData[0].fields.loadingCharges}`, 17.825, 7.766);
                                        doc.text(`${parsedData[0].fields.lrCharges}`, 17.825, 8.9);
                                        doc.text(`${parsedData[0].fields.subTotal}`, 17.825, 10.033);
                                        doc.text(`${parsedData[0].fields.grandTotal}`, 17.825, 14.566);
                                        doc.text(`Amount In Words : ${parsedData[0].fields.amountInWords}`, 1.2, 14.566);
                                    }
                                    if (parsedData[0].fields.rcm === "No") {
                                        doc.text(`${parsedData[0].fields.sgstUtgst}`, 17.825, 11.166);
                                        doc.text(`${parsedData[0].fields.cgst}`, 17.825, 12.3);
                                        doc.text(`${parsedData[0].fields.igst}`, 17.825, 13.433);
                                    } else {
                                        doc.text("0", 17.825, 11.166);
                                        doc.text("0", 17.825, 12.3);
                                        doc.text("0", 17.825, 13.433);
                                    }
                                    doc.setFont("Calibri");
                                    doc.text("All consignement booked at Owner's Risk only.", 1.4, 26.865);
                                    doc.text("Consigement should be insured by consigner or consignee.", 1.4, 27.265);
                                    doc.text("We are not responsible for breakage, leakage and damage.", 1.4, 27.665);
                                    doc.text("Any dispute subject to Pune Jurisdiction only.", 1.4, 28.065);
                                    doc.text("GST to be calculate under rcm by the service recipient.", 1.4, 28.465);

                                    if (copies.length - 1 !== index) {
                                        doc.addPage();
                                    }
                                });
                                let blobPdf = new Blob([doc.output('blob')], { type: "application/pdf" });
                                let _url = window.URL.createObjectURL(blobPdf);
                                window.open(_url, "_blank", "popup=no");
                                window.URL.revokeObjectURL(_url);
                                props.setMessage(false);
                                setLoading(false);
                            }).catch(() => {
                                props.setMessage(false);
                                setLoading(false);
                            })
                        });
                    });
                });
            });
        } else {
            props.setMessage(false);
            setLoading(false);
        }
    }
    const handleBillPrint = () => {
        setLoading(true);
        const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
        const locationUrl = `${authenticate.apiUrl}/api/location/`;
        fetch(locationUrl, {
            method: "GET",
            headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
        }).then(locationResponse => {
            if (locationResponse.status === 200) {
                return locationResponse.json();
            } else {
                // setErrorMsg(response.statusText);
            }
        }).then(locationData => {
            let parsedLocationData = JSON.parse(locationData);
            const clientUrl = `${authenticate.apiUrl}/api/client/`;
            fetch(clientUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(clientResponse => {
                if (clientResponse.status === 200) {
                    return clientResponse.json();
                } else {
                    // setErrorMsg(response.statusText);
                }
            }).then(clientData => {
                let parsedClientData = JSON.parse(clientData);
                const descriptionUrl = `${authenticate.apiUrl}/api/description/`;
                fetch(descriptionUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(descriptionResponse => {
                    if (descriptionResponse.status === 200) {
                        return descriptionResponse.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(descriptionData => {
                    let parsedDescriptionData = JSON.parse(descriptionData);
                    const gcnNumberUrl = `${authenticate.apiUrl}/api/gcn/?gcnNo=S-${props.message.billNum}`;
                    fetch(gcnNumberUrl, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            // setErrorMsg(response.statusText);
                        }
                    }).then(data => {
                        let parsedData = JSON.parse(data);
                        const doc = new jsPDF({ unit: "cm" });

                        doc.setDrawColor(0, 0, 0);
                        doc.setLineWidth(0.01);
                        doc.addFileToVFS("Calibri-Bold.ttf", calibriBold);
                        doc.addFont("Calibri-Bold.ttf", "Calibrib", "normal");
                        doc.addFileToVFS("Calibri-Regular.ttf", calibriRegular);
                        doc.addFont("Calibri-Regular.ttf", "Calibri", "normal");

                        doc.setFont("Calibrib");
                        doc.setFontSize(10);
                        // doc.setTextColor(0, 0, 70);
                        doc.setTextColor(0, 0, 0);
                        doc.text("TAX-INVOICE", 10.5, 1, "center");
                        doc.addImage(sstSymbol, "webp", 1, 1.3, 2, 1, "Symbol");
                        doc.addImage(sstLogo, "webp", 3.2, 1.3, 6.65, 1, "Logo");
                        doc.setFont("Calibri");
                        doc.setFontSize(7);
                        doc.text("Uruli Kanchan,Pune-Solapur Road,Tal-Haveli,Dist-Pune,412202", 1, 2.6);
                        doc.text("No.:AHHPD8257G / GSTIN No.:27AHHPD8257G2Z7", 1, 2.9);
                        doc.text("Contact No.:9422331609 Mail ID:sstransline99@gmail.com", 1, 3.2);
                        doc.setFont("Calibrib");
                        doc.setFontSize(9);
                        doc.text("Bill To", 1, 3.7);
                        doc.text(":", 2.5, 3.7);
                        doc.text("GSTIN No", 1, 5);
                        doc.text(":", 2.5, 5);
                        parsedClientData.forEach(ele => {
                            if (parsedData[0].fields.billTo === "consigner") {
                                if (ele.pk === parsedData[0].fields.consigner) {
                                    doc.text(ele.fields.clientName, 2.7, 3.7);
                                    doc.text(ele.fields.gstNo, 2.7, 5);
                                    doc.setFont("Calibri");
                                    doc.setFontSize(8);
                                    doc.text(ele.fields.clientAddress, 2.7, 4.2, { maxWidth: 9 });
                                }
                            } else {
                                if (ele.pk === parsedData[0].fields.consignee) {
                                    doc.text(ele.fields.clientName, 2.7, 3.7);
                                    doc.text(ele.fields.gstNo, 2.7, 5);
                                    doc.setFont("Calibri");
                                    doc.setFontSize(8);
                                    doc.text(ele.fields.clientAddress, 2.7, 4.2, { maxWidth: 9 });
                                }
                            }
                        });
                        doc.text("Address", 1, 4.2);
                        doc.text(":", 2.5, 4.2);
                        doc.setFont("Calibrib");
                        doc.setFontSize(10);
                        doc.text("Tax Invoice No.", 13.2, 1.7);
                        doc.text(":", 15.7, 1.7);
                        let invoiceDate = new Date(parsedData[0].fields.invoiceDate);
                        // doc.text(`${String(invoiceDate.getFullYear()).slice(-2)}${String(invoiceDate.getFullYear() + 1).slice(-2)}0${props.message.billNum}`, 16, 1.7);
                        doc.text(`${props.message.billNum}`, 16, 1.7);
                        doc.setFontSize(9);
                        doc.text("Invoice Date", 13.2, 2.3);
                        doc.text(":", 15.7, 2.3);
                        doc.text(`${invoiceDate.getDate()}-${invoiceDate.getMonth() + 1}-${invoiceDate.getFullYear()}`, 16, 2.3);
                        doc.text("Vehicle No.", 13.2, 2.9);
                        doc.text(":", 15.7, 2.9);
                        doc.text(parsedData[0].fields.truckNo.replace(" ", ""), 16, 2.9);
                        doc.text("State Code", 13.2, 3.5);
                        doc.text(":", 15.7, 3.5);
                        doc.text("27", 16, 3.5);
                        doc.text("Service Category", 13.2, 4.1);
                        doc.text(":", 15.7, 4.1);
                        doc.text("Road Transport service of goods", 16, 4.1, { maxWidth: 4 });
                        doc.text("Service Acc Code", 13.2, 5);
                        doc.text(":", 15.7, 5);
                        doc.text("996511", 16, 5);
                        doc.rect(1, 5.5, 1.2, 0.7);
                        doc.rect(2.2, 5.5, 1.5, 0.7);
                        doc.rect(3.7, 5.5, 4.2, 0.7);
                        doc.rect(7.9, 5.5, 0.8, 0.7);
                        doc.rect(8.7, 5.5, 1.5, 0.7);
                        doc.rect(10.2, 5.5, 1.5, 0.7);
                        doc.rect(11.7, 5.5, 1.5, 0.7);
                        doc.rect(13.2, 5.5, 1.5, 0.7);
                        doc.rect(14.7, 5.5, 1, 0.7);
                        doc.rect(15.7, 5.5, 1.5, 0.7);
                        doc.rect(17.2, 5.5, 1.1, 0.7);
                        doc.rect(18.3, 5.5, 1.7, 0.7);
                        doc.text("GCN No", 1.1, 6);
                        doc.text("GCN Date", 2.3, 6);
                        doc.text("Description", 3.8, 6);
                        doc.text("Pkg", 8.0, 6);
                        doc.text("Act.Wt", 8.8, 6);
                        doc.text("Cha.Wt", 10.3, 6);
                        doc.text("Freight", 11.8, 6);
                        doc.text("Loading", 13.3, 6);
                        doc.text("Crain", 14.8, 6);
                        doc.text("Other", 15.8, 6);
                        doc.text("LR", 17.3, 6);
                        doc.text("Sub Total", 18.4, 6);
                        doc.rect(1, 6.2, 1.2, 0.7);
                        doc.rect(2.2, 6.2, 1.5, 0.7);
                        doc.rect(3.7, 6.2, 4.2, 0.7);
                        doc.rect(7.9, 6.2, 0.8, 0.7);
                        doc.rect(8.7, 6.2, 1.5, 0.7);
                        doc.rect(10.2, 6.2, 1.5, 0.7);
                        doc.rect(11.7, 6.2, 1.5, 0.7);
                        doc.rect(13.2, 6.2, 1.5, 0.7);
                        doc.rect(14.7, 6.2, 1, 0.7);
                        doc.rect(15.7, 6.2, 1.5, 0.7);
                        doc.rect(17.2, 6.2, 1.1, 0.7);
                        doc.rect(18.3, 6.2, 1.7, 0.7);
                        doc.text(parsedData[0].pk, 1.1, 6.7);
                        let gcnDate = new Date(parsedData[0].fields.gcnDate);
                        doc.text(`${gcnDate.getDate() < 10 ? `0${gcnDate.getDate()}` : gcnDate.getDate()}-${(gcnDate.getMonth() + 1) < 10 ? `0${gcnDate.getMonth() + 1}` : gcnDate.getMonth() + 1}-${String(gcnDate.getFullYear()).slice(-2)}`, 2.3, 6.7);
                        parsedDescriptionData.forEach(ele => {
                            if (ele.pk === parsedData[0].fields.description) {
                                doc.text(ele.fields.product, 3.8, 6.7);
                            }
                        });
                        doc.text(`${parsedData[0].fields.actualPakage}`, 8.0, 6.7);
                        doc.text(`${parsedData[0].fields.actualWeight} Kg`, 8.8, 6.7);
                        doc.text(`${parsedData[0].fields.chargedWeight} Kg`, 10.3, 6.7);
                        doc.text(`${parsedData[0].fields.basicFreight}.00`, 11.8, 6.7);
                        doc.text(`${parsedData[0].fields.loadingCharges}.00`, 13.3, 6.7);
                        doc.text(`${parsedData[0].fields.craneCharges}.00`, 14.8, 6.7);
                        doc.text(`${parsedData[0].fields.fovCharges}.00`, 15.8, 6.7);
                        doc.text(`${parsedData[0].fields.lrCharges}.00`, 17.3, 6.7);
                        doc.text(`${parsedData[0].fields.subTotal}.00`, 18.4, 6.7);
                        doc.rect(1, 6.9, 12.2, 3);
                        doc.text("Inv No.", 1.1, 7.35);
                        doc.text(":", 2.5, 7.35);
                        doc.text(parsedData[0].fields.invoiceNo, 2.7, 7.35, { maxWidth: 5 });
                        doc.text("From", 1.1, 8.1);
                        doc.text(":", 2.5, 8.1);
                        parsedLocationData.forEach(ele => {
                            if (ele.pk === parsedData[0].fields.pickupLocation) {
                                doc.text(ele.fields.location, 2.7, 8.1);
                            }
                        });
                        doc.text("Consignor", 1.1, 8.85);
                        doc.text(":", 2.5, 8.85);
                        doc.text("Consignee", 1.1, 9.6);
                        doc.text(":", 2.5, 9.6);
                        parsedClientData.forEach(ele => {
                            if (ele.pk === parsedData[0].fields.consigner) {
                                doc.text(ele.fields.clientName, 2.7, 8.85);
                                doc.text(ele.fields.gstNo, 10.4, 8.85);
                            }
                            if (ele.pk === parsedData[0].fields.consignee) {
                                doc.text(ele.fields.clientName, 2.7, 9.6);
                                doc.text(ele.fields.gstNo, 10.4, 9.6);
                            }
                        });
                        doc.text("Inv Date", 8.8, 7.35);
                        doc.text(":", 10.2, 7.35);
                        doc.text(`${invoiceDate.getDate()}-${invoiceDate.getMonth() + 1}-${invoiceDate.getFullYear()}`, 10.4, 7.35);
                        doc.text("To", 8.8, 8.1);
                        doc.text(":", 10.2, 8.1);
                        parsedLocationData.forEach(ele => {
                            if (ele.pk === parsedData[0].fields.deliveryLocation) {
                                doc.text(ele.fields.location, 10.4, 8.1);
                            }
                        });
                        doc.text("GSTIN No.", 8.8, 8.85);
                        doc.text(":", 10.2, 8.85);
                        doc.text("GSTIN No.", 8.8, 9.6);
                        doc.text(":", 10.2, 9.6);
                        doc.rect(13.2, 6.9, 5.1, 0.7);
                        doc.rect(18.3, 6.9, 1.7, 0.7);
                        doc.rect(13.2, 7.6, 5.1, 0.7);
                        doc.rect(18.3, 7.6, 1.7, 0.7);
                        doc.rect(13.2, 8.3, 5.1, 0.7);
                        doc.rect(18.3, 8.3, 1.7, 0.7);
                        doc.rect(13.2, 9, 5.1, 0.9);
                        doc.rect(18.3, 9, 1.7, 0.9);
                        doc.text("(+) SGST/UTGST @ 2.5%", 13.3, 7.4);
                        if (parsedData[0].fields.rcm === "Yes") {
                            doc.text("0", 18.4, 7.4);
                        } else {
                            doc.text(`${parsedData[0].fields.sgstUtgst}`, 18.4, 7.4);
                        }
                        doc.text("(+) CGST @ 2.5%", 13.3, 8.1);
                        if (parsedData[0].fields.rcm === "Yes") {
                            doc.text("0", 18.4, 8.1);
                        } else {
                            doc.text(`${parsedData[0].fields.cgst}`, 18.4, 8.1);
                        }
                        doc.text("(+) IGST @ 5%", 13.3, 8.8);
                        if (parsedData[0].fields.rcm === "Yes") {
                            doc.text("0", 18.4, 8.8);
                        } else {
                            doc.text(`${parsedData[0].fields.igst}`, 18.4, 8.8);
                        }
                        doc.setFontSize(10);
                        doc.text("Total Bill Amount ₹", 13.3, 9.55);
                        doc.text(`${parsedData[0].fields.grandTotal}.00`, 18.4, 9.55);
                        doc.rect(1, 9.9, 12.2, 0.9);
                        doc.text(`Amount In Words : ${parsedData[0].fields.amountInWords}`, 1.1, 10.45);
                        doc.setFontSize(9);
                        doc.rect(1, 10.8, 7.7, 0.7);
                        doc.rect(8.7, 10.8, 4.5, 0.7);
                        doc.rect(1, 11.5, 7.7, 0.7);
                        doc.rect(8.7, 11.5, 4.5, 0.7);
                        doc.text("1) Whether GST is Payable Reverse Charges :", 1.1, 11.3);
                        doc.text(parsedData[0].fields.rcm, 8.8, 11.3);
                        doc.text("2) If Yes,amount of GST subject to RCM :", 1.1, 12);
                        if (parsedData[0].fields.rcm === "Yes") {
                            doc.text(`${parsedData[0].fields.sgstUtgst + parsedData[0].fields.cgst + parsedData[0].fields.igst}.00`, 8.8, 12);
                        } else {
                            doc.text("0.00", 8.8, 12);
                        }
                        doc.rect(1, 12.2, 12.2, 0.7);
                        doc.text(`Remark : ${parsedData[0].fields.remark}`, 1.1, 12.7);
                        doc.rect(1, 12.9, 12.2, 1.3);
                        doc.text("Declaration : ", 1.1, 13.2);
                        doc.setFontSize(7);
                        doc.setFont("Calibri");
                        doc.text("I/we hereby certified that my/our registration certificate under the GST act 2017 is in force on the ", 2.9, 13.2, { maxWidth: 10 });
                        doc.text("date on which the supply of goods specified in this tax invoice has been effected by me/us and it shall be accounted for in the turn over of supplies while filling of return and the due tax.If any,payable on the safe has been paid or shall be paid.", 1.1, 13.5, { maxWidth: 11 });
                        doc.setFontSize(9);
                        doc.setFont("Calibrib");
                        doc.rect(13.2, 9.9, 6.8, 4.3);
                        doc.text("E&OE", 19.7, 10.45, "right");
                        doc.text("For S.S.Translines", 16.6, 10.8, "center");
                        doc.addImage(signature, "png", 15.75, 11, 2.5, 2.5, "Signature");
                        doc.text("Authorized Signatory", 16.6, 14, "center");
                        doc.setTextColor(233, 66, 53);
                        doc.text("This is a auto generated Invoice & need no sign.", 16.6, 14.5, "center");

                        let blobPdf = new Blob([doc.output('blob')], { type: "application/pdf" });
                        let _url = window.URL.createObjectURL(blobPdf);
                        window.open(_url, "_blank", "popup=no");
                        window.URL.revokeObjectURL(_url);
                        props.setMessage(false);
                        setLoading(false);
                    }).catch(() => {
                        props.setMessage(false);
                        setLoading(false);
                    })
                });
            });
        });
    }
    const handleMessage = () => {
        props.setMessage(false);
        if (props.message.navigateHome) {
            navigate("/");
        }
    }
    const handleTripReport = () => {
        setLoading(true);
        let fromDate = document.getElementById("fromDate");
        let toDate = document.getElementById("toDate");
        let all = document.getElementById("all");
        const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
        if (all.checked) {
            const allTripUrl = `${authenticate.apiUrl}/api/${props.message.message.slice(4)}/?all=True`;
            fetch(allTripUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    // setErrorMsg(response.statusText);
                }
            }).then(data => {
                let parsedData = JSON.parse(data);
                let reportData = [];
                let sumOfTotalExpenses = 0;
                let sumOfBalance = 0;
                parsedData.forEach(element => {
                    element.fields.expences = JSON.parse(element.fields.expences);
                    let tempStr = "";
                    Object.keys(element.fields.expences).forEach((ele, index) => {
                        tempStr += `${ele} = ${element.fields.expences[ele]}`;
                        if (Object.keys(element.fields.expences).length - 1 !== index) {
                            tempStr += ` & `;
                        }
                    });
                    element.fields.expences = tempStr;
                    sumOfTotalExpenses += element.fields.totalExpenses === null ? 0 : element.fields.totalExpenses;
                    sumOfBalance += element.fields.balance === null ? 0 : element.fields.balance;
                    reportData.push(element.fields);
                });
                reportData.push({ "date": null, "tripFrom": null, "tripTo": null, "broker": null, "transporter": null, "consigner": null, "consignee": null, "truckHire": null, "advance": null, "expences": null, "totalExpenses": sumOfTotalExpenses, "balance": sumOfBalance, "balancePaidDate": null });
                const worksheet = utils.json_to_sheet(reportData);
                const workbook = utils.book_new();
                utils.book_append_sheet(workbook, worksheet, "Sheet 1");
                writeFileXLSX(workbook, `${props.message.message}AllReport.xlsx`);
                props.setMessage(false);
                setLoading(false);
            }).catch(() => {
                props.setMessage(false);
                setLoading(false);
            });
        } else {
            if (fromDate.value !== "" && toDate.value !== "") {
                const allTripUrl = `${authenticate.apiUrl}/api/${props.message.message.slice(4)}/?fromDate=${fromDate.value}&toDate=${toDate.value}`;
                fetch(allTripUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    let parsedData = JSON.parse(data);
                    let reportData = [];
                    let sumOfTotalExpenses = 0;
                    let sumOfBalance = 0;
                    parsedData.forEach(element => {
                        element.fields.expences = JSON.parse(element.fields.expences);
                        let tempStr = "";
                        Object.keys(element.fields.expences).forEach((ele, index) => {
                            tempStr += `${ele} = ${element.fields.expences[ele]}`;
                            if (Object.keys(element.fields.expences).length - 1 !== index) {
                                tempStr += ` & `;
                            }
                        });
                        element.fields.expences = tempStr;
                        sumOfTotalExpenses += element.fields.totalExpenses === null ? 0 : element.fields.totalExpenses;
                        sumOfBalance += element.fields.balance === null ? 0 : element.fields.balance;
                        reportData.push(element.fields);
                    });
                    reportData.push({ "date": null, "tripFrom": null, "tripTo": null, "broker": null, "transporter": null, "consigner": null, "consignee": null, "truckHire": null, "advance": null, "expences": null, "totalExpenses": sumOfTotalExpenses, "balance": sumOfBalance, "balancePaidDate": null });
                    const worksheet = utils.json_to_sheet(reportData);
                    const workbook = utils.book_new();
                    utils.book_append_sheet(workbook, worksheet, "Sheet 1");
                    writeFileXLSX(workbook, `${props.message.message}SelectedReport.xlsx`);
                    props.setMessage(false);
                    setLoading(false);
                }).catch(() => {
                    props.setMessage(false);
                    setLoading(false);
                });
            } else {
                props.setMessage(false);
                setLoading(false);
            }
        }
    }
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
        window.onscroll = () => {
            window.scrollTo({
                top: 0,
                left: 0
            });
        }
        if (props.message.billPrint) {
            handleBillPrint();
        }
        return () => {
            window.onscroll = () => { };
        }
        // eslint-disable-next-line
    }, []);
    return (
        <>
            <div id="overley-back"></div>
            <div id={props.message.gcnPrint || props.message.tripPrint ? "messageBoxChanged" : "messageBox"}>
                <div className="messageTopDiv">
                    {props.message.gcnPrint ? <><div>
                        <input type="checkbox" id="driverCopy" value="DriverCopy" />
                        <label htmlFor="driverCopy">Driver's Copy</label>
                    </div>
                        <div>
                            <input type="checkbox" id="consigneeCopy" value="ConsigneeCopy" />
                            <label htmlFor="consigneeCopy">Consignee's Copy</label>
                        </div>
                        <div>
                            <input type="checkbox" id="consignerCopy" value="ConsignerCopy" />
                            <label htmlFor="consignerCopy">Consignor's Copy</label>
                        </div>
                        <div>
                            <input type="checkbox" id="billingCopy" value="BillingCopy" />
                            <label htmlFor="billingCopy">Billing's Copy</label>
                        </div>
                        <div>
                            <input type="checkbox" id="fileCopy" value="FileCopy" />
                            <label htmlFor="fileCopy">File's Copy</label>
                        </div></> : props.message.tripPrint ? <div className="TripReport">
                            <div>
                                <label htmlFor="fromDate">From</label>
                            </div>
                            <div>
                                <input type="date" name="FromDate" id="fromDate" />
                            </div>
                            <div>
                                <label htmlFor="toDate">To</label>
                            </div>
                            <div>
                                <input type="date" id="toDate" name="ToDate" />
                            </div>
                            <div>
                                <label htmlFor="all">All</label>
                            </div>
                            <div>
                                <input type="checkbox" id="all" name="All" />
                            </div>
                        </div> : props.message.message}
                </div>
                <div>
                    {loading ? <Spinner /> : props.message.gcnPrint ? <input type="button" value="Print" className="navbarButton" id="PrintBtn" onClick={handleGcnPrint} /> : props.message.tripPrint ? <input type="button" value="Generate" className="navbarButton" onClick={handleTripReport} /> : <input type="button" value="Ok" className="navbarButton" onClick={handleMessage} />}
                </div>
            </div>
        </>
    )
}

export default MessageBox
