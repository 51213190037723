import React from 'react';
import '../css/subNavbar.css';
import { Link } from 'react-router-dom';

const SubNavbar = (props) => {
    return (
        <div>
            <div className="navBarItems">
                <button className="navigationButton">{props.itemMainTitle}</button>
                <div className="navbarDropdowns">
                    {props.itemsIncluded.map(ele => {
                        return <Link key={ele.url} to={ele.url} className="dropdownLinks">{ele.title}</Link>
                    })}
                </div>
            </div>
        </div>
    )
}

export default SubNavbar
