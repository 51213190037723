import SstContext from "./SstContext"
import { useState } from "react"

const SstState = (props) => {
    const apiUrl = "https://www.sstransline.com";
    const [isLogin, setIsLogin] = useState(false);
    const [theme, setTheme] = useState("light");
    const [userName, setUserName] = useState("");
    const [admin, setAdmin] = useState(false);
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState(false);
    const handleAuthenticate = (value) => {
        setIsLogin(value);
    }
    const handleTheme = () => {
        if (theme === "light"){
            setTheme("dark");
        }else{
            setTheme("light");
        }
    }
    const handleUserName = (value) => {
        setUserName(value);
    }
    const handleAdmin = (value) => {
        setAdmin(value);
    }
    const handleProgress = (value) => {
        setProgress(value);
    }
    return (
        <SstContext.Provider value={{apiUrl, isLogin, handleAuthenticate, theme, handleTheme, userName, handleUserName, admin, handleAdmin, progress, handleProgress, message, setMessage}}>
            {props.children}
        </SstContext.Provider>
    )
}

export default SstState
