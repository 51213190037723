import React from 'react';
import '../css/header.css';
import Mail from './Mail';
import Phone from './Phone';
import Whatsapp from './Whatsapp';

const Header = (props) => {
  return (
    <div id="ContactInfo">
        <div>
            <Phone />
            <div className="contactText">{props.contactNumber}</div>
        </div>
        <div>
            <Mail />
            <div className="contactText">{props.mailId}</div>
        </div>
        <div>
            <Whatsapp />
            <div className="contactText">{props.whatsappNumber}</div>
        </div>
    </div>
  )
}

export default Header
