import React, { useContext, useEffect, useState } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';
import { utils, writeFileXLSX } from 'xlsx';

const BrokerReport = () => {
    const authenticate = useContext(SstContext);
    const [broker, setBroker] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            } else {
                document.title = "SSTransline | Broker Report";
                authenticate.handleProgress(10);
                const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
                const brokerUrl = `${authenticate.apiUrl}/api/broker/`;
                authenticate.handleProgress(30);
                fetch(brokerUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        authenticate.handleProgress(50);
                        return response.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    let parsedData = JSON.parse(data);
                    setBroker(parsedData);
                    authenticate.handleProgress(100);
                });
            }
        }
        // eslint-disable-next-line
    }, []);
    const items = [{ "label": "Broker", "id": "broker", "type": "select" }, { "label": "From", "id": "from", "type": "date" }, { "label": "To", "id": "to", "type": "date" }];
    const validateBrokerReport = () => {
        if (document.getElementById("broker").value === "select") {
            authenticate.setMessage({ message: "Broker Cannot Be Blank" });
            return false;
        } else if (document.getElementById("from").value === "") {
            authenticate.setMessage({ message: "From Cannot Be Blank" });
            return false;
        } else if (document.getElementById("to").value === "") {
            authenticate.setMessage({ message: "To Cannot Be Blank" });
            return false;
        }
        return true;
    }
    const handleBrokerReport = () => {
        if (validateBrokerReport()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const BrokerReportUrl = `${authenticate.apiUrl}/api/broker/?broker=${document.getElementById("broker").value}&from=${document.getElementById("from").value}&to=${document.getElementById("to").value}`;
            fetch(BrokerReportUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    // setErrorMsg(response.statusText);
                }
            }).then(data => {
                let parsedData = JSON.parse(data);
                let reportData = [];
                let sumOfBalance = 0;
                parsedData.forEach(element => {
                    sumOfBalance += element.balance === null ? 0 : parseInt(element.balance);
                    reportData.push(element);
                });
                reportData.push({ "brokerName": null, "gcnNo": null, "gcnDate": null, "from": null, "to": null, "truckNo": null, "truckHire": null, "totalAdvance": null, "balance": sumOfBalance, "balancePaidDate": null });
                const worksheet = utils.json_to_sheet(reportData);
                const workbook = utils.book_new();
                utils.book_append_sheet(workbook, worksheet, "Sheet 1");
                writeFileXLSX(workbook, `BrokerReport.xlsx`);
                authenticate.setMessage(false);
            });
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="brokerReportInput">
                <div className="heading">
                    <div>Broker Report</div>
                </div>
                <div className="brokerReport">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} broker={broker} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="submit" id="brokerReport" className="navbarButton" value="Report" onClick={handleBrokerReport} />
                </div>
            </section>}
        </>
    )
}

export default BrokerReport
