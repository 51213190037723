import React, { useContext, useEffect, useState } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import LabelAdd from './LabelAdd';
import '../css/sstOwn.css';
import Edit from './Edit';

const SstOwn = (props) => {
    const authenticate = useContext(SstContext);
    const [expenses, setExpenses] = useState({});
    const [truckHire, setTruckHire] = useState(0);
    const [balance, setBalance] = useState(0);
    const [totalExpense, setTotalExpense] = useState(0);
    const [tenTripData, setTenTripData] = useState([]);
    const [allTripData, setAllTripData] = useState([]);
    const [editClicked, setEditClicked] = useState(false);
    const [filteredTrips, setFilteredTrips] = useState([]);
    const [selectedEditTrip, setSelectedEditTrip] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            } else {
                document.title = "SSTransline | SST Own";
            }
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (authenticate.admin) {
            try{
                document.getElementById("date").value = "";
                document.getElementById("from").value = "";
                document.getElementById("to").value = "";
                document.getElementById("broker").value = "";
                document.getElementById("transporter").value = "";
                document.getElementById("consigner").value = "";
                document.getElementById("consignee").value = "";
                document.getElementById("advance").value = "";
                document.getElementById("expenses").value = "";
                document.getElementById("balancePaidDate").value = "";
            } catch (err) {
                setEditClicked(false);
                setFilteredTrips([]);
                setSelectedEditTrip(false);
            }
            setTruckHire(0);
            setExpenses({});
            setTotalExpense(0);
            setBalance(0);
            // Sending request to get all trip data
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const getTripUrl = `${authenticate.apiUrl}/api/${props.truckNo.slice(4)}/?all=True`;
            fetch(getTripUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                }
            }).then(data => {
                let parsedData = JSON.parse(data);
                setAllTripData(parsedData);
                setTenTripData(parsedData.maxLength > 10 ? parsedData.reverse().slice(0, 10) : parsedData.reverse());
            });
        }
    }, [props.truckNo]);
    const validateTrip = () => {
        if (document.getElementById("from").value === "") {
            authenticate.setMessage({ message: "From Cannot Be Blank" });
            return false;
        } else if (document.getElementById("to").value === "") {
            authenticate.setMessage({ message: "To Cannot Be Blank" });
            return false;
        }
        return true;
    }
    const handleAddTrip = () => {
        if (validateTrip()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            let tripData = {
                "date": document.getElementById("date").value === "" ? null : document.getElementById("date").value,
                "tripFrom": document.getElementById("from").value,
                "tripTo": document.getElementById("to").value,
                "broker": document.getElementById("broker").value === "" ? null : document.getElementById("broker").value,
                "transporter": document.getElementById("transporter").value === "" ? null : document.getElementById("transporter").value,
                "consigner": document.getElementById("consigner").value === "" ? null : document.getElementById("consigner").value,
                "consignee": document.getElementById("consignee").value === "" ? null : document.getElementById("consignee").value,
                "truckHire": truckHire,
                "advance": document.getElementById("advance").value === "" ? null : document.getElementById("advance").value,
                "expences": JSON.stringify(expenses),
                "totalExpenses": totalExpense,
                "balance": balance,
                "balancePaidDate": document.getElementById("balancePaidDate").value === "" ? null : document.getElementById("balancePaidDate").value
            }
            const addTripUrl = `${authenticate.apiUrl}/api/${props.truckNo.slice(4)}/`;
            fetch(addTripUrl, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                body: JSON.stringify(tripData)
            }).then(response => {
                if (response.status === 200) {
                    authenticate.setMessage({ message: "Trip Added Successfully", navigateHome: true });
                } else {
                    authenticate.setMessage({ message: response.statusText });
                }
            });
        }
    }
    const handleCalculateBalance = (event) => {
        let refrence = {
            "truckHire": setTruckHire,
            "totalExpenses": setTotalExpense,
            "balance": setBalance,
        };
        if (event.target.value !== "" && parseInt(event.target.value) > 0) {
            let localTruckHire = truckHire;
            let localTotalExpense = totalExpense;
            let localBalance = balance;
            if (event.target.id === "truckHire") {
                localTruckHire = event.target.value;
            } else if (event.target.id === "totalExpenses") {
                localTotalExpense = event.target.value;
            }
            localBalance = parseInt(localTruckHire) - parseInt(localTotalExpense);
            setBalance(localBalance);
            let removedZeroes = parseInt(event.target.value);
            setTimeout(() => {
                refrence[event.target.id](removedZeroes);
            }, 10)
        } else {
            let localTruckHire = truckHire;
            let localTotalExpense = totalExpense;
            let localBalance = balance;
            if (event.target.id === "truckHire") {
                localTruckHire = 0;
            } else if (event.target.id === "totalExpenses") {
                localTotalExpense = 0;
            }
            localBalance = parseInt(localTruckHire) - parseInt(localTotalExpense);
            setBalance(localBalance);
            refrence[event.target.id]("0");
        }

    }
    const handleTripReport = () => {
        authenticate.setMessage({ message: props.truckNo, tripPrint: true });
    }
    const items = [{ "label": "Date", "id": "date", "type": "date" }, { "label": "From", "id": "from", "type": "text" }, { "label": "To", "id": "to", "type": "text" }, { "label": "Broker", "id": "broker", "type": "text" }, { "label": "Transporter", "id": "transporter", "type": "text" }, { "label": "Consignor", "id": "consigner", "type": "text" }, { "label": "Consignee", "id": "consignee", "type": "text" }, { "label": "Truck Hire", "id": "truckHire", "type": "number", "value": truckHire, "onChange": handleCalculateBalance }, { "label": "Advance", "id": "advance", "type": "number" }, { "label": "Expenses", "id": "expenses", "type": "labelAdd" }, { "label": "Total Expenses", "id": "totalExpenses", "type": "number", "value": totalExpense, "onChange": handleCalculateBalance }, { "label": "Balance", "id": "balance", "type": "number", "value": balance, "onChange": handleCalculateBalance }, { "label": "Balance Paid Date", "id": "balancePaidDate", "type": "date" }];
    const handleEdit = () => {
        setEditClicked(true);
    }
    const handleDateChanged = (event) => {
        let tripsToEdit = [];
        allTripData.forEach(ele => {
            if (ele.fields.date === event.target.value) {
                tripsToEdit.push(ele);
            }
        });
        setFilteredTrips(tripsToEdit);
    }
    const handleEditEntry = (element) => {
        setTruckHire(element.fields.truckHire);
        let parsedExpenses = JSON.parse(element.fields.expences);
        Object.keys(parsedExpenses).forEach(ele => {
            parsedExpenses[ele] = parseInt(parsedExpenses[ele]);
        });
        setExpenses(parsedExpenses);
        setTotalExpense(element.fields.totalExpenses);
        setBalance(element.fields.balance);
        setSelectedEditTrip(element);
    }
    const validateEditTrip = () => {
        if (document.getElementById("tripFrom").value === "") {
            authenticate.setMessage({ message: "From Cannot Be Blank" });
            return false;
        } else if (document.getElementById("tripTo").value === "") {
            authenticate.setMessage({ message: "To Cannot Be Blank" });
            return false;
        }
        return true;
    }
    const handleActualEdit = () => {
        if (validateEditTrip()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            let tripData = {
                "pk" : selectedEditTrip.pk,
                "date": document.getElementById("date").value === "" ? null : document.getElementById("date").value,
                "tripFrom": document.getElementById("tripFrom").value,
                "tripTo": document.getElementById("tripTo").value,
                "broker": document.getElementById("broker").value === "" ? null : document.getElementById("broker").value,
                "transporter": document.getElementById("transporter").value === "" ? null : document.getElementById("transporter").value,
                "consigner": document.getElementById("consigner").value === "" ? null : document.getElementById("consigner").value,
                "consignee": document.getElementById("consignee").value === "" ? null : document.getElementById("consignee").value,
                "truckHire": truckHire,
                "advance": document.getElementById("advance").value === "" ? null : document.getElementById("advance").value,
                "expences": JSON.stringify(expenses),
                "totalExpenses": totalExpense,
                "balance": balance,
                "balancePaidDate": document.getElementById("balancePaidDate").value === "" ? null : document.getElementById("balancePaidDate").value
            }
            const editTripUrl = `${authenticate.apiUrl}/api/${props.truckNo.slice(4)}/`;
            fetch(editTripUrl, {
                method: "PUT",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                body: JSON.stringify(tripData)
            }).then(response => {
                if (response.status === 200) {
                    authenticate.setMessage({ message: "Trip Edited Successfully", navigateHome: true });
                } else {
                    authenticate.setMessage({ message: response.statusText });
                }
            });
        }
    }
    const handleValueChange = (event) => {
        let local_select_edit_trip = Object.assign({}, selectedEditTrip);
        local_select_edit_trip.fields[event.target.id] = event.target.value;
        setSelectedEditTrip(local_select_edit_trip);
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="truckSection">
                <div className="heading">
                    <div>{editClicked ? 'Edit' : 'Add'} Trip ( {props.truckNo.toUpperCase()} )</div>
                </div>
                {editClicked ? <div>
                    {selectedEditTrip ? <>
                        <div className="truckSection">
                            <LabelInput label="Date" id="date" type="date" value={selectedEditTrip.fields.date} onChange={handleValueChange} />
                            <LabelInput label="From" id="tripFrom" type="text" value={selectedEditTrip.fields.tripFrom} onChange={handleValueChange} />
                            <LabelInput label="To" id="tripTo" type="text" value={selectedEditTrip.fields.tripTo} onChange={handleValueChange} />
                            <LabelInput label="Broker" id="broker" type="text" value={selectedEditTrip.fields.broker} onChange={handleValueChange} />
                            <LabelInput label="Transporter" id="transporter" type="text" value={selectedEditTrip.fields.transporter} onChange={handleValueChange} />
                            <LabelInput label="Consignor" id="consigner" type="text" value={selectedEditTrip.fields.consigner} onChange={handleValueChange} />
                            <LabelInput label="Consignee" id="consignee" type="text" value={selectedEditTrip.fields.consignee} onChange={handleValueChange} />
                            <LabelInput label="Truck Hire" id="truckHire" type="number" value={truckHire} onChange={handleCalculateBalance} />
                            <LabelInput label="Advance" id="advance" type="number" value={selectedEditTrip.fields.advance} onChange={handleValueChange} />
                            <LabelAdd id="expenses" label="Expenses" expenses={expenses} setExpenses={setExpenses} setTotalExpense={setTotalExpense} totalExpense={totalExpense} truckHire={truckHire} setBalance={setBalance} />
                            <LabelInput label="Total Expenses" id="totalExpenses" type="number" value={totalExpense} onChange={handleCalculateBalance} />
                            <LabelInput label="Balance" id="balance" type="number" value={balance} onChange={handleCalculateBalance} />
                            <LabelInput label="Balance Paid Date" id="balancePaidDate" type="date" value={selectedEditTrip.fields.balancePaidDate} onChange={handleValueChange} />
                            {/* {editedItem.map(element => {
                                return element.type === "labelAdd" ? <LabelAdd key={element.id} id={element.id} label={element.label} expenses={expenses} setExpenses={setExpenses} setTotalExpense={setTotalExpense} totalExpense={totalExpense} truckHire={truckHire} setBalance={setBalance} /> : <LabelInput key={element.id} id={element.id} value={element.value !== "" ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                            })} */}
                        </div>
                        <div id="btnContainer">
                            <input type="button" value="Edit" id="actualEditBtn" className="navbarButton" onClick={handleActualEdit} />
                        </div>
                    </> : <>
                        <div className='editDateSection'>
                            <LabelInput id="sstOwnDate" label="Date" type="date" onChange={handleDateChanged} />
                        </div>
                        <div className='filteredResults'>
                            {(filteredTrips.length > 0) && <><div>From</div>
                            <div>To</div>
                            <div>Edit</div>
                            </>}
                            {filteredTrips.map(ele => {
                                return <>
                                <div>
                                    {ele.fields.tripFrom}
                                </div>
                                <div>
                                    {ele.fields.tripTo}
                                </div>
                                <div>
                                    <div onClick={() => handleEditEntry(ele)}>
                                        <Edit />
                                    </div>
                                    {/* <input key={ele.pk} type="button" value="Edit" id="editEntryBtn" className="navbarButton" onClick={() => handleEditEntry(ele)} /> */}
                                </div>
                                </>
                            })}
                        </div>
                    </>}
                </div> : <>
                    <div className="truckSection">
                        {items.map(element => {
                            return element.type === "labelAdd" ? <LabelAdd key={element.id} id={element.id} label={element.label} expenses={expenses} setExpenses={setExpenses} setTotalExpense={setTotalExpense} totalExpense={totalExpense} truckHire={truckHire} setBalance={setBalance} /> : <LabelInput key={element.id} id={element.id} value={element.value !== "" ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                        })}
                    </div>
                    <div id="btnContainer">
                        <input type="submit" id="addTrip" className="navbarButton" value="Add" onClick={handleAddTrip} />
                        <input type="button" value="Report" id="reportBtn" className="navbarButton" onClick={handleTripReport} />
                        <input type="button" value="Edit" id="editBtn" className="navbarButton" onClick={handleEdit} />
                        {/* <input type="button" value="Print" id="printBtn" className="navbarButton" /> */}
                    </div>
                    <div className='tripsSection'>
                        {tenTripData && <>
                            <div className='tripHeadGrid'>
                                <div>Date</div>
                                <div>From</div>
                                <div>To</div>
                                {/* <div>Broker</div>
                            <div>Transporter</div>
                            <div>Consigner</div>
                            <div>Consignee</div> */}
                                <div>Truck Hire</div>
                                <div>Advance</div>
                                <div>Expences</div>
                                <div>Total Expense</div>
                                <div>Balance</div>
                                <div>Balance Paid Date</div>
                            </div>
                            {tenTripData.map((ele, index) => {
                                return <React.Fragment key={index}>
                                    <div className={`${tenTripData.length === index + 1 ? 'tripResultGrid lastChild' : 'tripResultGrid'}`}>
                                        <div>{ele.fields.date}</div>
                                        <div>{ele.fields.tripFrom}</div>
                                        <div>{ele.fields.tripTo}</div>
                                        {/* <div>{ele.fields.broker}</div>
                                    <div>{ele.fields.transporter}</div>
                                    <div>{ele.fields.consigner}</div>
                                    <div>{ele.fields.consignee}</div> */}
                                        <div>{ele.fields.truckHire}</div>
                                        <div>{ele.fields.advance}</div>
                                        <div className='expensesGrid'>
                                            {Object.keys(JSON.parse(ele.fields.expences)).map((item, ind) => {
                                                return <React.Fragment key={`${item}${ind}${index}`}>
                                                    <div>{item}</div>
                                                    <div>{JSON.parse(ele.fields.expences)[item]}</div>
                                                </React.Fragment>
                                            })}
                                        </div>
                                        <div>{ele.fields.totalExpenses}</div>
                                        <div>{ele.fields.balance}</div>
                                        <div>{ele.fields.balancePaidDate}</div>
                                    </div>
                                </React.Fragment>
                            })}
                        </>}
                    </div>
                </>}
            </section>}
        </>
    )
}

export default SstOwn
