import React from 'react';
import '../css/logout.css';

const Logout = () => {
  return (
    <section className="logoutSection">
        <svg viewBox="0 0 100 100">
            <path d="M 35 15 c 0 0 -25 10 -25 35 M 10 50 a 1 1 0 0 0 80 0 c 0 0 0 -27 -24 -35 M 50 5 v 30"></path>
        </svg>
    </section>
  )
}

export default Logout
