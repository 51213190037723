import React, { useContext, useEffect, useState } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import TableUtility from './TableUtility';
import SelectInput from './SelectInput';

const ThcModifyAndPrint = () => {
    const authenticate = useContext(SstContext);
    const navigate = useNavigate();
    const [thcNumbers, setThcNumbers] = useState([]);
    const [thcDeleted, setThcDeleted] = useState(0);
    const [searchThc, setSearchThc] = useState(["select"]);
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (authenticate.admin) {
                document.title = "SSTransline | THC Modify And Print";
                authenticate.handleProgress(10);
                const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
                const thcNumbersUrl = `${authenticate.apiUrl}/api/thc/?allNumbersOnly=True`;
                authenticate.handleProgress(30);
                fetch(thcNumbersUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        authenticate.handleProgress(50);
                        return response.json();
                    } else {
                        authenticate.handleProgress(100);
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    authenticate.handleProgress(70);
                    let parsedData = JSON.parse(data);
                    setThcNumbers(parsedData.thcNumbers.reverse());
                    setSearchThc(["select"]);
                    authenticate.handleProgress(100);
                });
            } else {
                navigate("/");
            }
        }
    }, [thcDeleted])
    const items = {
        "heading": {
            "className": "thcResultHeading heading",
            "titles": ["Sr.No", "Thc", "Print", "Modify", "Cancel"]
        },
        "content": {
            "className": "thcResults",
            "gcnNumbers": thcNumbers.length < 10 ? thcNumbers : thcNumbers.slice(0, 10)
        }
    }
    const searchItems = {
        "heading": {
            "className": "thcResultHeading heading",
            "titles": ["Sr.No", "Thc", "Print", "Modify", "Cancel"]
        },
        "content": {
            "className": "thcResults",
            "gcnNumbers": searchThc
        }
    }
    const handleSearchThc = (event) => {
        if (event.target.value !== "select"){
            setSearchThc([event.target.value]);
        }else{
            setSearchThc(["select"]);
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section>
                <div className="selectThc">
                    <SelectInput id="searchThc" value={searchThc[0]} onChange={handleSearchThc} label="Select Thc No" options={false} thcNumbers={thcNumbers} />
                </div>
                <div className="thcResult">
                    {searchThc[0] === "select" ? <TableUtility item={items} setThcDeleted={setThcDeleted} thcDeleted={thcDeleted}/> : <TableUtility item={searchItems} setThcDeleted={setThcDeleted} thcDeleted={thcDeleted}/>}
                </div>
            </section>}
        </>
    )
}

export default ThcModifyAndPrint
