import React, { useContext, useEffect } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';

const AddDriver = () => {
    const authenticate = useContext(SstContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            }else{
                document.title = "SSTransline | Driver";
            }
        }
        // eslint-disable-next-line
    }, []);
    const items = [{ "label": "Driver Name", "id": "driverName", "type": "text" }, { "label": "Driver Address", "id": "driverAddress", "type": "textArea", "cols": "30", "rows": "10" }, { "label": "Driver Contact No", "id": "driverContactNumber", "type": "text" }, { "label": "Liscence No", "id": "liscenceNumber", "type": "text" }, { "label": "Aadhar Number", "id": "aadharNumber", "type": "number" }];
    const validateDriver = () => {
        if (document.getElementById("driverName").value === "") {
            authenticate.setMessage({message:"Driver Name Cannot Be Blank"});
            return false;
        }
        return true;
    }
    const handleAddDriver = () => {
        if (validateDriver()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            let driverData = {
                "driverName" : document.getElementById("driverName").value,
                "driverAddress" : document.getElementById("driverAddress").value === "" ? null : document.getElementById("driverAddress").value,
                "driverContactNumber" : document.getElementById("driverContactNumber").value === "" ? null : document.getElementById("driverContactNumber").value,
                "liscenceNumber" : document.getElementById("liscenceNumber").value === "" ? null : document.getElementById("liscenceNumber").value,
                "adharNumber" : document.getElementById("aadharNumber").value === "" ? null : parseInt(document.getElementById("aadharNumber").value)
              }
            const addDriverUrl = `${authenticate.apiUrl}/api/driver/`;
            fetch(addDriverUrl, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                body: JSON.stringify(driverData)
            }).then(response => {
                if (response.status === 200) {
                    authenticate.setMessage({message:"Driver Added Successfully", navigateHome:true});
                } else {
                    authenticate.setMessage({message:response.statusText});
                }
            });
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="addDriverSection">
                <div className="heading">
                    <div>Add Driver</div>
                </div>
                <div className="driverSection">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="submit" id="addDriver" className="navbarButton" value="Add Driver" onClick={handleAddDriver} />
                </div>
            </section>}
        </>
    )
}

export default AddDriver
