import React, { useContext, useEffect, useState } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';
import { utils, writeFileXLSX } from 'xlsx';

const OutstandingBill = () => {
    const authenticate = useContext(SstContext);
    const [client, setClient] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            } else {
                document.title = "SSTransline | Outstanding Bill Report";
                authenticate.handleProgress(10);
                const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
                const clientUrl = `${authenticate.apiUrl}/api/client/`;
                fetch(clientUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        authenticate.handleProgress(30);
                        return response.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    authenticate.handleProgress(50);
                    let parsedData = JSON.parse(data);
                    setClient(parsedData);
                    authenticate.handleProgress(100);
                });
            }
        }
        // eslint-disable-next-line
    }, []);
    const items = [{ "label": "Bill To", "id": "billTo", "type": "select" }, { "label": "From", "id": "from", "type": "date" }, { "label": "To", "id": "to", "type": "date" }, { "label": "All", "id": "all", "type": "checkbox" }];
    const validateOutstandingBillReport = () => {
        if (document.getElementById("billTo").value === "select") {
            authenticate.setMessage({ message: "Bill To Cannot Be Blank" });
            return false;
        }else if ((document.getElementById("from").value === "" || document.getElementById("to").value === "") && !document.getElementById("all").checked) {
            authenticate.setMessage({ message: "from , to or all Cannot Be Blank" });
            return false;
        }
        return true;
    }
    const handleOutstandingBillReport = () => {
        if (validateOutstandingBillReport()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            let outstandingBillAllReportUrl;
            if (document.getElementById("all").checked) {
                outstandingBillAllReportUrl = `${authenticate.apiUrl}/api/bill/?client=${document.getElementById("billTo").value}&all=True`;
            } else {
                outstandingBillAllReportUrl = `${authenticate.apiUrl}/api/bill/?from=${document.getElementById("from").value}&to=${document.getElementById("to").value}&outstanding=True&client=${document.getElementById("billTo").value}`;
            }
            fetch(outstandingBillAllReportUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    // setErrorMsg(response.statusText);
                }
            }).then(data => {
                let parsedData = JSON.parse(data);
                let reportData = [];
                let billAmountTotal = 0;
                let days30Total = 0;
                let days45Total = 0;
                let days60Total = 0;
                let daysAbove60Total = 0;
                parsedData.forEach(element => {
                    let billDate = new Date(element.billDate);
                    let todayDate = new Date();
                    let differenceBetweenDates = Math.ceil(Math.abs(todayDate - billDate) / (1000 * 60 * 60 * 24));
                    let days30 = '';
                    let days45 = '';
                    let days60 = '';
                    let daysAbove60 = '';
                    billAmountTotal += element.billAmount;
                    if (differenceBetweenDates <= 30) {
                        days30 = element.billAmount;
                        days30Total += element.billAmount;
                    }
                    else if (differenceBetweenDates <= 45) {
                        days45 = element.billAmount;
                        days45Total += element.billAmount;
                    }
                    else if (differenceBetweenDates <= 60) {
                        days60 = element.billAmount;
                        days60Total += element.billAmount;
                    }
                    else {
                        daysAbove60 = element.billAmount;
                        daysAbove60Total += element.billAmount;
                    }
                    reportData.push({ "billNo": element.billNo, "billDate": element.billDate, "billAmount": element.billAmount, "0-30 days": days30, "31-45 days": days45, "46-60 days": days60, "Over 60 days": daysAbove60, "Remark": null });
                    reportData.push(element);
                });
                reportData.push({ "billNo": null, "billDate": null, "billAmount": billAmountTotal, "0-30 days": days30Total, "31-45 days": days45Total, "46-60 days": days60Total, "Over 60 days": daysAbove60Total, "Remark": null });
                const worksheet = utils.json_to_sheet(reportData);
                const workbook = utils.book_new();
                utils.book_append_sheet(workbook, worksheet, "Sheet 1");
                writeFileXLSX(workbook, `OutstandingBillReport.xlsx`);
                authenticate.setMessage(false);
            });
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="outstandingBillInput">
                <div className="heading">
                    <div>Outstanding Bill Report</div>
                </div>
                <div className="outstandingBillReport">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} clients={client} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="submit" id="outstandingBillReportBtn" className="navbarButton" value="Report" onClick={handleOutstandingBillReport} />
                </div>
            </section>}
        </>
    )
}

export default OutstandingBill
