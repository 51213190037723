import React, { useContext, useEffect, useState } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';
import { jsPDF } from "jspdf";
import { calibriRegular, calibriBold } from "../fonts/Calibri";
import { sstLogoBlack } from '../images/SstSymbol';

const Envelop = () => {
    const authenticate = useContext(SstContext);
    const [adress, setAddress] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            } else {
                document.title = "SSTransline | Envelop";
                const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
                const envelopAddressUrl = `${authenticate.apiUrl}/api/envelopaddress/`;
                fetch(envelopAddressUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    let parsedData = JSON.parse(data);
                    setAddress(parsedData);
                });
            }
        }
        // eslint-disable-next-line
    }, []);
    const handleAddressChanged = (event) => {
        if (event.target.value != "select") {
            document.getElementById("to").value = event.target.value;
        } else {
            document.getElementById("to").value = "";
        }
    }
    const items = [{ "label": "To", "id": "to", "type": "textArea", "rows": "30", "cols": "30" }, { "label": "Saved Addresses", "id": "savedAddresses", "type": "select", "onChange": handleAddressChanged }];
    const handleEnvelopPrint = () => {
        const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
        let elementInaddress = true;
        adress.forEach(element => {
            if (element.fields.address === document.getElementById("to").value && document.getElementById("to").value !== "") {
                elementInaddress = false;
            }
        });
        if (elementInaddress && document.getElementById("to").value !== ""){
            let envelopAddressData = {
                "address": document.getElementById("to").value
            }
            const addennvelopAddressUrl = `${authenticate.apiUrl}/api/envelopaddress/`;
            fetch(addennvelopAddressUrl, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                body: JSON.stringify(envelopAddressData)
            });
        }
        const doc = new jsPDF({orientation: "l" , unit: "cm", format: [24.13, 10.4775]});

        doc.setDrawColor(127, 127, 127);
        doc.setLineWidth(0.01);
        doc.addFileToVFS("Calibri-Bold.ttf", calibriBold);
        doc.addFont("Calibri-Bold.ttf", "Calibrib", "normal");
        doc.addFileToVFS("Calibri-Regular.ttf", calibriRegular);
        doc.addFont("Calibri-Regular.ttf", "Calibri", "normal");

        doc.setFont("Calibri");
        doc.setFontSize(10);
        doc.text("From :", 1, 6.4);
        doc.addImage(sstLogoBlack, "png", 1, 6.5, 6.65, 1, "Logo");
        doc.text("Muktangan Complex, Opp. Kasturi Mangal Karyalaya", 1, 7.8);
        doc.text("Uruli Kanchan, Pune-Solapur Road, Tal-Haveli, Dist-Pune, 412202", 1, 8.2);
        doc.text("Mail ID : sstransline99@gmail.com, Website : www.sstransline.com", 1, 8.6);
        doc.text("Contact No. : 9422331609 / 9689388938", 1, 9);
        doc.setFontSize(12);
        doc.text(`To , ${document.getElementById("to").value}`, 10, 1, {maxWidth: 13});

        let blobPdf = new Blob([doc.output('blob')], { type: "application/pdf" });
        let _url = window.URL.createObjectURL(blobPdf);
        window.open(_url, "_blank", "popup=no");
        window.URL.revokeObjectURL(_url);
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="envelopTopSection">
                <div className="heading">
                    <div>Envelop Print</div>
                </div>
                <div className="envelopSection">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} adress={adress} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="button" id="printEnvelop" className="navbarButton" value="Print" onClick={handleEnvelopPrint} />
                </div>
            </section>}
        </>
    )
}

export default Envelop
