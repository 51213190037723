import React, { useContext, useEffect, useState } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';

const AddTruckDetails = () => {
    const authenticate = useContext(SstContext);
    const [broker, setBroker] = useState([]);
    const [owner, setOwner] = useState([]);
    const [driver, setDriver] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            }else{
                document.title = "SSTransline | Truck Detail";
                authenticate.handleProgress(10);
                const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
                const brokerUrl = `${authenticate.apiUrl}/api/broker/`;
                authenticate.handleProgress(30);
                fetch(brokerUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    let parsedData = JSON.parse(data);
                    setBroker(parsedData);
                });
                authenticate.handleProgress(50);
                const ownerUrl = `${authenticate.apiUrl}/api/owner/`;
                fetch(ownerUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    let parsedData = JSON.parse(data);
                    setOwner(parsedData);
                });
                authenticate.handleProgress(70);
                const driverUrl = `${authenticate.apiUrl}/api/driver/`;
                fetch(driverUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    let parsedData = JSON.parse(data);
                    setDriver(parsedData);
                });
                authenticate.handleProgress(100);
            }
        }
        // eslint-disable-next-line
    }, []);
    const items = [{ "label": "Truck No", "id": "truckNumber", "type": "text" }, { "label": "Engine No", "id": "engineNumber", "type": "text" }, { "label": "Chasis No", "id": "chasisNumber", "type": "text" }, { "label": "Ins Policy No", "id": "insurancePolicyNumber", "type": "text" }, { "label": "Ins Validity", "id": "insuranceValidity", "type": "date" }, { "label": "Permit Validity", "id": "permitValidity", "type": "date" }, { "label": "Fitness Validity", "id": "fitnessValidity", "type": "date" }, { "label": "PUC Validity", "id": "pucValidity", "type": "date" }, { "label": "Company Make", "id": "companyMake", "type": "text" }, { "label": "Broker", "id": "broker", "type": "select" }, { "label": "Owner", "id": "owner", "type": "select" }, { "label": "Driver", "id": "driver", "type": "select" }];
    const validateTruckDetails = () => {
        if (document.getElementById("truckNumber").value === "") {
            authenticate.setMessage({message:"Truck Number Cannot Be Blank"});
            return false;
        }
        return true;
    }
    const handleAddTruckDetails = () => {
        if (validateTruckDetails()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            let truckDetailsData = {
                "truckNumber": document.getElementById("truckNumber").value,
                "engineNumber": document.getElementById("engineNumber").value === "" ? null : document.getElementById("engineNumber").value,
                "chasisNumber": document.getElementById("chasisNumber").value === "" ? null : document.getElementById("chasisNumber").value,
                "insurancePolicyNumber": document.getElementById("insurancePolicyNumber").value === "" ? null : document.getElementById("insurancePolicyNumber").value,
                "insuranceValidity": document.getElementById("insuranceValidity").value === "" ? null : document.getElementById("insuranceValidity").value,
                "permitValidity": document.getElementById("permitValidity").value === "" ? null : document.getElementById("permitValidity").value,
                "fitnessValidity": document.getElementById("fitnessValidity").value === "" ? null : document.getElementById("fitnessValidity").value,
                "pucValidity": document.getElementById("pucValidity").value === "" ? null : document.getElementById("pucValidity").value,
                "companyMake": document.getElementById("companyMake").value === "" ? null : document.getElementById("companyMake").value,
                "broker": document.getElementById("broker").value === "select" ? null : document.getElementById("broker").value,
                "owner": document.getElementById("owner").value === "select" ? null : document.getElementById("owner").value,
                "driver": document.getElementById("driver").value === "select" ? null : document.getElementById("driver").value
            }
            const addTruckDetailsUrl = `${authenticate.apiUrl}/api/truckdetail/`;
            fetch(addTruckDetailsUrl, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                body: JSON.stringify(truckDetailsData)
            }).then(response => {
                if (response.status === 200) {
                    authenticate.setMessage({message:"Truck Details Added Successfully", navigateHome:true});
                } else {
                    authenticate.setMessage({message:response.statusText});
                }
            });
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="addTruckSection">
                <div className="heading">
                    <div>Add Truck Details</div>
                </div>
                <div className="truckSection">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} broker={broker} owner={owner} driver={driver} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="submit" id="addTruck" className="navbarButton" value="Add Truck" onClick={handleAddTruckDetails} />
                </div>
            </section>}
        </>
    )
}

export default AddTruckDetails
