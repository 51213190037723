import React from 'react';
import '../css/labelInput.css';

const LabelInput = (props) => {
    return (
        <>
            {props.divId ? <div id={props.divId}>
                <div>
                    <label htmlFor={props.id}>{props.label}</label>
                </div>
                <div>
                    {props.value !== false && props.onChange !== false ? <input type={props.type} id={props.id} value={props.value} onChange={props.onChange} /> : props.readOnly === true && props.value !== false ? <input type={props.type} id={props.id} value={props.value} readOnly /> : props.maxLength !== false ? <input type={props.type} id={props.id} maxLength={props.maxLength} /> : props.type === "textArea" ? <textarea id={props.id} cols={props.cols} rows={props.rows}></textarea> : <input type={props.type} id={props.id} />}
                </div>
            </div> : <div>
                <div>
                    <label htmlFor={props.id}>{props.label}</label>
                </div>
                <div>
                    {props.value !== false && props.onChange !== false && props.maxLength !== false ? <input type={props.type} id={props.id} value={props.value} maxLength={props.maxLength} onChange={props.onChange} /> : props.value !== false && props.onChange !== false ? <input type={props.type} id={props.id} value={props.value} onChange={props.onChange} /> : props.onChange !== false ? <input type={props.type} id={props.id} onChange={props.onChange} /> : props.readOnly === true && props.value !== false ? <input type={props.type} id={props.id} value={props.value} readOnly /> : props.accept ? <input type={props.type} id={props.id} accept={props.accept} /> : props.value !== false ? <input type={props.type} id={props.id} value={props.value} /> : props.maxLength !== false ? <input type={props.type} id={props.id} maxLength={props.maxLength} /> : props.type === "textArea" ? <textarea id={props.id} cols={props.cols} rows={props.rows}></textarea> : <input type={props.type} id={props.id} />}
                </div>
            </div>}
        </>
    )
}

export default LabelInput
