import React, { useContext, useEffect, useState } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';

const BillSubmission = (props) => {
    const authenticate = useContext(SstContext);
    const [billNumbers, setBillNumbers] = useState([]);
    const [client, setClient] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (authenticate.admin) {
                authenticate.handleProgress(10);
                const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
                const gcnNumbersUrl = `${authenticate.apiUrl}/api/gcn/?allNumbersOnly=True`;
                fetch(gcnNumbersUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    let parsedData = JSON.parse(data);
                    let billNumbers = parsedData.gcnNumbers.reverse().map(element => {
                        return element.slice(2);
                    });
                    setBillNumbers(billNumbers);
                });
                authenticate.handleProgress(50);
                const clientUrl = `${authenticate.apiUrl}/api/client/`;
                fetch(clientUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        authenticate.handleProgress(70);
                        return response.json();
                    } else {
                        authenticate.handleProgress(100);
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    authenticate.handleProgress(100);
                    let parsedData = JSON.parse(data);
                    setClient(parsedData);
                });
            } else {
                navigate("/");
            }
        }
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (authenticate.admin) {
                if (props.edit){
                    document.title = "SSTransline | Bill Collection";
                }else{
                    document.title = "SSTransline | Bill Submission";
                }
                authenticate.handleProgress(50);
                document.getElementById("billNo").value = "select";
                document.getElementById("billDate").value = "";
                document.getElementById("billTo").value = "";
                document.getElementById("billAmount").value = "";
                document.getElementById("billSubmissionPoint").value = "";
                document.getElementById("billSubmissionDate").value = "";
                authenticate.handleProgress(100);
            }else{
                navigate("/");
            }
        }
    }, [props.edit]);
    const handleBillNumber = (event) => {
        if (event.target.value !== "select") {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const gcnNumbersUrl = `${authenticate.apiUrl}/api/gcn/?gcnNo=${event.target.value}`;
            const billNoUrl = `${authenticate.apiUrl}/api/bill/?gcn=${event.target.value}`;
            fetch(gcnNumbersUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    // setErrorMsg(response.statusText);
                }
            }).then(data => {
                let parsedData = JSON.parse(data);
                document.getElementById("billDate").value = parsedData[0].fields.gcnDate;
                if (parsedData[0].fields.billTo === "consigner") {
                    client.forEach(ele => {
                        if (ele.pk === parsedData[0].fields.consigner) {
                            document.getElementById("billTo").value = ele.fields.clientName;
                        }
                    });
                } else {
                    client.forEach(ele => {
                        if (ele.pk === parsedData[0].fields.consignee) {
                            document.getElementById("billTo").value = ele.fields.clientName;
                        }
                    });
                }
                document.getElementById("billAmount").value = parsedData[0].fields.subTotal;
                if (!props.edit) {
                    document.getElementById("sgstUtgst").value = parsedData[0].fields.sgstUtgst;
                    document.getElementById("cgst").value = parsedData[0].fields.cgst;
                    document.getElementById("igst").value = parsedData[0].fields.igst;
                    document.getElementById("totalBillAmount").value = parsedData[0].fields.grandTotal;
                } else {
                    fetch(billNoUrl, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            // setErrorMsg(response.statusText);
                        }
                    }).then(billData => {
                        let billParsedData = JSON.parse(billData);
                        if (billParsedData.length !== 0) {
                            document.getElementById("billSubmissionPoint").value = billParsedData[0].fields.billSubmissionPoint === null ? "" : billParsedData[0].fields.billSubmissionPoint;
                            document.getElementById("billSubmissionDate").value = billParsedData[0].fields.billSubDate === null ? "" : billParsedData[0].fields.billSubDate;
                            document.getElementById("tdsAmount").value = billParsedData[0].fields.tdsAmount === null ? "" : billParsedData[0].fields.tdsAmount;
                            document.getElementById("paymentReceivedAmount").value = billParsedData[0].fields.paymentReceivedAmount === null ? "" : billParsedData[0].fields.paymentReceivedAmount;
                            document.getElementById("collectionDate").value = billParsedData[0].fields.collectionDate === null ? "" : billParsedData[0].fields.collectionDate;
                            document.getElementById("billRemark").value = billParsedData[0].fields.remark === null ? "" : billParsedData[0].fields.remark;
                        } else {
                            document.getElementById("billSubmissionPoint").value = "";
                            document.getElementById("billSubmissionDate").value = "";
                            document.getElementById("tdsAmount").value = "";
                            document.getElementById("paymentReceivedAmount").value = "";
                            document.getElementById("collectionDate").value = "";
                            document.getElementById("billRemark").value = "";
                        }
                    });
                }
            });
        } else {
            document.getElementById("billDate").value = "";
            document.getElementById("billTo").value = "";
            document.getElementById("billAmount").value = "";
            if (!props.edit) {
                document.getElementById("sgstUtgst").value = "";
                document.getElementById("cgst").value = "";
                document.getElementById("igst").value = "";
                document.getElementById("totalBillAmount").value = "";
            } else {
                document.getElementById("billSubmissionPoint").value = "";
                document.getElementById("billSubmissionDate").value = "";
                document.getElementById("tdsAmount").value = "";
                document.getElementById("paymentReceivedAmount").value = "";
                document.getElementById("collectionDate").value = "";
                document.getElementById("billRemark").value = "";
            }
        }
    }
    const validateBill = () => {
        if (document.getElementById("billNo").value === "select") {
            authenticate.setMessage({message :"Bill Number Cannot Be Blank"});
            return false;
        }
        return true;
    }
    const handleBillSubmission = () => {
        if (validateBill()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            if (!props.edit) {
                let billData = {
                    "gcn": document.getElementById("billNo").value,
                    "billSubmissionPoint": document.getElementById("billSubmissionPoint").value === "" ? null : document.getElementById("billSubmissionPoint").value,
                    "billSubDate": document.getElementById("billSubmissionDate").value === "" ? null : document.getElementById("billSubmissionDate").value,
                    "tdsAmount": null,
                    "paymentReceivedAmount": null,
                    "collectionDate": null,
                    "remark": null
                }
                const addBillUrl = `${authenticate.apiUrl}/api/bill/`;
                fetch(addBillUrl, {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                    body: JSON.stringify(billData)
                }).then(response => {
                    if (response.status === 200) {
                        authenticate.setMessage({message:"Bill Submitted Successfully", navigateHome:true});
                    } else {
                        authenticate.setMessage({message:response.statusText});
                    }
                });
            } else {
                let billData = {
                    "gcn": document.getElementById("billNo").value,
                    "billSubmissionPoint": document.getElementById("billSubmissionPoint").value === "" ? null : document.getElementById("billSubmissionPoint").value,
                    "billSubDate": document.getElementById("billSubmissionDate").value === "" ? null : document.getElementById("billSubmissionDate").value,
                    "tdsAmount": document.getElementById("tdsAmount").value === "" ? null : document.getElementById("tdsAmount").value,
                    "paymentReceivedAmount": document.getElementById("paymentReceivedAmount").value === "" ? null : document.getElementById("paymentReceivedAmount").value,
                    "collectionDate": document.getElementById("collectionDate").value === "" ? null : document.getElementById("collectionDate").value,
                    "remark": document.getElementById("billRemark").value === "" ? null : document.getElementById("billRemark").value
                }
                const addBillUrl = `${authenticate.apiUrl}/api/bill/`;
                fetch(addBillUrl, {
                    method: "PUT",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                    body: JSON.stringify(billData)
                }).then(response => {
                    if (response.status === 200) {
                        authenticate.setMessage({message:"Bill Collected Successfully", navigateHome:true});
                    } else {
                        authenticate.setMessage({message:response.statusText});
                    }
                });
            }
        }
    };
    const items = [{ "label": "Bill No", "id": "billNo", "type": "select", "onChange": handleBillNumber }, { "label": "Bill Date", "id": "billDate", "type": "date" }, { "label": "Bill To", "id": "billTo", "type": "textArea", "cols": "30", "rows": "10" }, { "label": "Bill Sub.Point", "id": "billSubmissionPoint", "type": "text" }, { "label": "Bill Sub.Date", "id": "billSubmissionDate", "type": "date" }, { "label": "Bill Amount", "id": "billAmount", "type": "number" }, { "label": "SGST/UTGST", "id": "sgstUtgst", "type": "number" }, { "label": "CGST", "id": "cgst", "type": "number" }, { "label": "IGST", "id": "igst", "type": "number" }, { "label": "Total Bill Amount", "id": "totalBillAmount", "type": "number" }];
    const editItems = [{ "label": "Bill No", "id": "billNo", "type": "select", "onChange": handleBillNumber }, { "label": "Bill Date", "id": "billDate", "type": "date" }, { "label": "Bill To", "id": "billTo", "type": "textArea", "cols": "30", "rows": "10" }, { "label": "Bill Sub.Point", "id": "billSubmissionPoint", "type": "text" }, { "label": "Bill Sub.Date", "id": "billSubmissionDate", "type": "date" }, { "label": "Bill Amount", "id": "billAmount", "type": "number" }, { "label": "TDS Amount", "id": "tdsAmount", "type": "number" }, { "label": "Payment Rec.Amount", "id": "paymentReceivedAmount", "type": "number" }, { "label": "Collection Date", "id": "collectionDate", "type": "date" }, { "label": "Remark", "id": "billRemark", "type": "text" }];
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="BillSection">
                <div className="heading">
                    {!props.edit ? <div>Bill Submission</div> : <div>Bill Collection</div>}
                </div>
                <div className="billSection">
                    {!props.edit ? items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} billNumbers={billNumbers} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    }) : editItems.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} billNumbers={billNumbers} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="submit" id="submitBill" className="navbarButton" value="Submit Bill" onClick={handleBillSubmission} />
                </div>
            </section>}
        </>
    )
}

export default BillSubmission
