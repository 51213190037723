import React, { useContext, useState, useEffect } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import TableUtility from './TableUtility';
import SelectInput from './SelectInput';

const BillPrint = () => {
    const authenticate = useContext(SstContext);
    const navigate = useNavigate();
    const [billNumbers, setBillNumbers] = useState([]);
    const [searchBill, setSearchBill] = useState(["select"]);
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (authenticate.admin) {
                document.title = "SSTransline | Bill Print";
                authenticate.handleProgress(10);
                const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
                const gcnNumbersUrl = `${authenticate.apiUrl}/api/gcn/?allNumbersOnly=True`;
                fetch(gcnNumbersUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        authenticate.handleProgress(50);
                        return response.json();
                    } else {
                        authenticate.handleProgress(100);
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    let parsedData = JSON.parse(data);
                    let billNumbers = parsedData.gcnNumbers.reverse().map(element => {
                        return element.slice(2);
                    });
                    setBillNumbers(billNumbers);
                    setSearchBill(["select"]);
                    authenticate.handleProgress(100);
                });
            } else {
                navigate("/");
            }
        }
        // eslint-disable-next-line
    }, []);
    const items = {
        "heading": {
            "className": "billResultHeading heading",
            "titles": ["Sr.No", "Bill", "Print"]
        },
        "content": {
            "className": "billResults",
            "gcnNumbers": billNumbers.length < 10 ? billNumbers : billNumbers.slice(0, 10)
        }
    }
    const searchItems = {
        "heading": {
            "className": "billResultHeading heading",
            "titles": ["Sr.No", "Bill", "Print"]
        },
        "content": {
            "className": "billResults",
            "gcnNumbers": searchBill
        }
    }
    const handleSearchBill = (event) => {
        if (event.target.value !== "select"){
            setSearchBill([event.target.value]);
        }else{
            setSearchBill(["select"]);
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section>
                <div className="selectBill">
                    <SelectInput id="SearchBill" value={searchBill[0]} onChange={handleSearchBill} label="Select Bill No" options={false} billNumbers={billNumbers} />
                </div>
                <div className="billResult">
                    {searchBill[0] === "select" ? <TableUtility item={items}/> : <TableUtility item={searchItems} />}
                </div>
            </section>}
        </>
    )
}

export default BillPrint
