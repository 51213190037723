import React, { useContext, useEffect } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';
import { jsPDF } from "jspdf";
import { calibriRegular, calibriBold } from "../fonts/Calibri";

const Cheque = () => {
    const authenticate = useContext(SstContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            } else {
                document.title = "SSTransline | Cheque";
            }
        }
        // eslint-disable-next-line
    }, []);
    const handleAmountInWords = (event) => {
        var a = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine ', 'Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ', 'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
        var b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
        function inWords(num) {
            if ((num = num.toString()).length > 9) return 'overflow';
            let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
            if (!n) return; var str = '';
            str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
            str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lakh ' : '';
            str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
            str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
            str += (n[5] != 0) ? ((str != '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + '' : '';
            return str;
        }
        if (event.target.value != "0") {
            document.getElementById("amountInWordsCheque").value = inWords(event.target.value) + "Only";
        }
    }
    const items = [{ "label": "Account Payee", "id": "accountPayee", "type": "checkbox" }, { "label": "Date", "id": "date", "type": "date" }, { "label": "Pay", "id": "pay", "type": "text" }, { "label": "Rupees", "id": "rupees", "type": "number", "onChange": handleAmountInWords }, { "label": "Amount in Words", "id": "amountInWordsCheque", "type": "text" }, { "label": "Bank", "id": "bank", "type": "select", "options": { "value": ["select", "sbi", "hdfc"] } }];
    const handleChequePrint = () => {
        const doc = new jsPDF({ orientation: "l", unit: "cm" });

        doc.setDrawColor(127, 127, 127);
        doc.setLineWidth(0.01);
        doc.addFileToVFS("Calibri-Bold.ttf", calibriBold);
        doc.addFont("Calibri-Bold.ttf", "Calibrib", "normal");
        doc.addFileToVFS("Calibri-Regular.ttf", calibriRegular);
        doc.addFont("Calibri-Regular.ttf", "Calibri", "normal");

        doc.setFont("Calibrib");
        doc.setFontSize(10);
        let dateFormatted = new Date(document.getElementById("date").value);
        let strDate = `${('0' + dateFormatted.getDate()).slice(-2)}-${('0' + (dateFormatted.getMonth() + 1)).slice(-2)}-${dateFormatted.getFullYear().toString()}`;
        let AcPayee = false;
        if (document.getElementById("accountPayee").checked){
            AcPayee = "A/C Payee";
        }
        if (document.getElementById("bank").value === "hdfc") {
            doc.text(strDate.charAt(0), 23.3, 7.45);
            doc.text(strDate.charAt(1), 23.8, 7.45);
            doc.text(strDate.charAt(3), 24.3, 7.45);
            doc.text(strDate.charAt(4), 24.8, 7.45);
            doc.text(strDate.charAt(6), 25.3, 7.45);
            doc.text(strDate.charAt(7), 25.8, 7.45);
            doc.text(strDate.charAt(8), 26.3, 7.45);
            doc.text(strDate.charAt(9), 26.8, 7.45);
            doc.setFontSize(12);
            doc.text(document.getElementById('pay').value, 9.5, 8.65);
            doc.text(document.getElementById('amountInWordsCheque').value, 11, 9.55);
            doc.text(document.getElementById('rupees').value, 23.5, 10.3);
            doc.setFontSize(10);
            if (AcPayee){
                doc.line(12.5, 6.7, 14, 6.7);
                doc.text(AcPayee, 12.5, 7);
                doc.line(12.5, 7.1, 14, 7.1);
            }
        }else if (document.getElementById("bank").value === "sbi"){
            doc.text(strDate.charAt(0), 23.3, 7.27);
            doc.text(strDate.charAt(1), 23.8, 7.27);
            doc.text(strDate.charAt(3), 24.3, 7.27);
            doc.text(strDate.charAt(4), 24.8, 7.27);
            doc.text(strDate.charAt(6), 25.3, 7.27);
            doc.text(strDate.charAt(7), 25.8, 7.27);
            doc.text(strDate.charAt(8), 26.3, 7.27);
            doc.text(strDate.charAt(9), 26.8, 7.27);
            doc.setFontSize(12);
            doc.text(document.getElementById('pay').value, 9.2, 8.65);
            doc.text(document.getElementById('amountInWordsCheque').value, 11, 9.5);
            doc.text(document.getElementById('rupees').value, 23.5, 10.1);
            doc.setFontSize(10);
            if (AcPayee){
                // doc.line(8.8, 6, 9.8, 5);
                doc.text(AcPayee, 8.6, 7.4, {align: 'center', angle: 45});
                // doc.line(9, 9, 6, 6);
            }
        }
        doc.autoPrint();

        let blobPdf = new Blob([doc.output('blob')], { type: "application/pdf" });
        let _url = window.URL.createObjectURL(blobPdf);
        window.open(_url, "_blank", "popup=no");
        window.URL.revokeObjectURL(_url);
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="chequePrintSection">
                <div className="heading">
                    <div>Cheque Print</div>
                </div>
                <div className="chequePrintInput">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="button" id="printCheque" className="navbarButton" value="Print" onClick={handleChequePrint} />
                </div>
            </section>}
        </>
    )
}

export default Cheque
