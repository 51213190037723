import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const Spinner = () => {
    return (
        <Box sx={{ textAlign:"center" }}>
            <CircularProgress thickness={5} size="1.8rem"/>
        </Box>
    )
}

export default Spinner
