import React from 'react';
import '../css/delete.css';

const Delete = () => {
  return (
    <section className="deleteSection">
        <svg viewBox="0 0 32 32">
            <path d="M25,10H7v17c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V10z" />
            <path d="M20,7h-8V5c0-1.105,0.895-2,2-2h4c1.105,0,2,0.895,2,2V7z" />
            <path d="M28,10H4V8c0-0.552,0.448-1,1-1h22c0.552,0,1,0.448,1,1V10z" />
            <line x1="13" x2="19" y1="16" y2="22" />
            <line x1="13" x2="19" y1="22" y2="16" />
        </svg>
    </section>
  )
}

export default Delete
