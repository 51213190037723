import React from 'react';
import '../css/hamburger.css';

const Hamburger = (props) => {
    const handleHamburgerClicked = () => {
        props.setIsClicked(!props.isClicked);
    }
    return (
        <button id="hamburger" className={props.isClicked ? "clicked" : null} onClick={handleHamburgerClicked}>
            <svg className="buttonsvg" viewBox="0 0 100 100">
                <line className="line top" x1="90" x2="10" y1="40" y2="40"></line>
                <line className="line bottom" x1="10" x2="90" y1="60" y2="60"></line>
            </svg>
        </button>
    )
}

export default Hamburger
