import React, { useContext, useEffect, useState } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import TableUtility from './TableUtility';
import '../css/gcnModifyAndPrint.css';
import SelectInput from './SelectInput';

const GcnModifyAndPrint = () => {
    const authenticate = useContext(SstContext);
    const navigate = useNavigate();
    const [gcnNumbers, setGcnNumbers] = useState([]);
    const [gcnDeleted, setGcnDeleted] = useState(0);
    const [searchGcn, setSearchGcn] = useState(["select"]);
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            document.title = "SSTransline | Gcn Modify And Print";
            authenticate.handleProgress(10);
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const gcnNumbersUrl = `${authenticate.apiUrl}/api/gcn/?allNumbersOnly=True`;
            authenticate.handleProgress(30);
            fetch(gcnNumbersUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    authenticate.handleProgress(50);
                    return response.json();
                } else {
                    authenticate.handleProgress(100);
                    // setErrorMsg(response.statusText);
                }
            }).then(data => {
                authenticate.handleProgress(70);
                let parsedData = JSON.parse(data);
                setGcnNumbers(parsedData.gcnNumbers.reverse());
                setSearchGcn(["select"]);
                authenticate.handleProgress(100);
            });
        }
    }, [gcnDeleted])
    const items = {
        "heading": {
            "className": "gcnResultHeading heading",
            "titles": ["Sr.No", "Gcn", "Print", "Modify", "Cancel"]
        },
        "content": {
            "className": "gcnResults",
            "gcnNumbers": gcnNumbers.length < 10 ? gcnNumbers : gcnNumbers.slice(0, 10)
        }
    }
    const searchItems = {
        "heading": {
            "className": "gcnResultHeading heading",
            "titles": ["Sr.No", "Gcn", "Print", "Modify", "Cancel"]
        },
        "content": {
            "className": "gcnResults",
            "gcnNumbers": searchGcn
        }
    }
    const handleSearchGcn = (event) => {
        if (event.target.value !== "select"){
            setSearchGcn([event.target.value]);
        }else{
            setSearchGcn(["select"]);
        }
    }
    return (
        <>
            {authenticate.isLogin && <section id="modifyAndPrintGcnSection">
                <div className="selectGcn">
                    <SelectInput id="searchGcn" value={searchGcn[0]} onChange={handleSearchGcn} label="Select Gcn No" options={false} gcnNumbers={gcnNumbers} />
                </div>
                <div className="gcnResult">
                    {searchGcn[0] === "select" ? <TableUtility item={items} setGcnDeleted={setGcnDeleted} gcnDeleted={gcnDeleted} /> : <TableUtility item={searchItems} setGcnDeleted={setGcnDeleted} gcnDeleted={gcnDeleted} />}
                </div>
            </section>}
        </>
    )
}

export default GcnModifyAndPrint
