import React, { useContext } from 'react'
import SstContext from '../contexts/SstContext';
import '../css/darkThemeButton.css';

const DarkThemeButton = () => {
    const authenticate = useContext(SstContext);
    const handleThemeChange = () => {
        let rootStyles = document.querySelector(":root");
        if (authenticate.theme === "light") {
            authenticate.handleTheme();
            rootStyles.style.setProperty("--strokeColor", "white");
            rootStyles.style.setProperty("--fillColor", "white");
            rootStyles.style.setProperty("--offwhite", "black");
            document.body.style.backgroundColor = "black";
            rootStyles.style.setProperty("--customBlue", "#3a72ba");
            rootStyles.style.setProperty("--customLightBlue", "#8aadda");
            rootStyles.style.setProperty("--inputBoxBorder", "0.15rem solid #8aadda");
            rootStyles.style.setProperty("--inputBoxBackgroundColor", "black");
            rootStyles.style.setProperty("--colorScheme", "dark");
        } else if (authenticate.theme === "dark") {
            authenticate.handleTheme();
            rootStyles.style.setProperty("--strokeColor", "black");
            rootStyles.style.setProperty("--fillColor", "black");
            rootStyles.style.setProperty("--offwhite", "#efefef");
            document.body.style.backgroundColor = "white";
            rootStyles.style.setProperty("--customBlue", "rgb(41, 81, 132)");
            rootStyles.style.setProperty("--customLightBlue", "rgb(163 187 218)");
            rootStyles.style.setProperty("--inputBoxBorder", "0.15rem solid rgb(195 213 236)");
            rootStyles.style.setProperty("--inputBoxBackgroundColor", "white");
            rootStyles.style.setProperty("--colorScheme", "light");
        }
    }
    return (
        <section className="darkSwitchSection" onClick={handleThemeChange}>
            <svg viewBox="0 0 100 100">
                <path d="m 50 5 a 1 1 0 0 0 0 90 v -90"></path>
                <path d="m 50 5 a 1 1 0 0 1 0 90"></path>
            </svg>
        </section>
    )
}

export default DarkThemeButton
