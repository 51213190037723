import React, { useContext, useEffect } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';

const AddDestination = () => {
    const authenticate = useContext(SstContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            }else{
                document.title = "SSTransline | Destination";
            }
        }
        // eslint-disable-next-line
    }, []);
    const items = [{ "label": "Location", "id": "location", "type": "text" }];
    const validateDestination = () => {
        if (document.getElementById("location").value === "") {
            authenticate.setMessage({message:"Location Cannot Be Blank"});
            return false;
        }
        return true;
    }
    const handleAddDestination = () => {
        if (validateDestination()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            let destinationData = {
                "location":document.getElementById("location").value
              }
            const addDestinationUrl = `${authenticate.apiUrl}/api/location/`;
            fetch(addDestinationUrl, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                body: JSON.stringify(destinationData)
            }).then(response => {
                if (response.status === 200) {
                    authenticate.setMessage({message:"Destination Added Successfully", navigateHome:true});
                } else {
                    authenticate.setMessage({message:response.statusText});
                }
            });
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="addDestinationSection">
                <div className="heading">
                    <div>Add Destination</div>
                </div>
                <div className="destinationSection">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="submit" id="addLocation" className="navbarButton" value="Add" onClick={handleAddDestination}/>
                </div>
            </section>}
        </>
    )
}

export default AddDestination
