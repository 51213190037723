import React, { useContext, useEffect } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';

const AddClient = () => {
    const authenticate = useContext(SstContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            }else{
                document.title = "SSTransline | Client";
            }
        }
        // eslint-disable-next-line
    }, []);
    const items = [{ "label": "Client Name", "id": "clientName", "type": "text" }, { "label": "Client Address", "id": "clientAddress", "type": "textArea", "cols": "30", "rows": "10" }, { "label": "Client Contact No", "id": "clientContactNumber", "type": "text" }, { "label": "GST No", "id": "gstNumber", "type": "text" }, { "label": "PAN No", "id": "panNumber", "type": "text" }];
    const validateClient = () => {
        if (document.getElementById("clientName").value === "") {
            authenticate.setMessage({message :"Client Name Cannot Be Blank"});
            return false;
        }
        return true;
    }
    const handleAddClient = () => {
        if (validateClient()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            let clientData = {
                "clientName" : document.getElementById("clientName").value,
                "clientAddress" : document.getElementById("clientAddress").value === "" ? null : document.getElementById("clientAddress").value,
                "clientContactNo" : document.getElementById("clientContactNumber").value === "" ? null : document.getElementById("clientContactNumber").value,
                "gstNo" : document.getElementById("gstNumber").value === "" ? null : document.getElementById("gstNumber").value,
                "panNo" : document.getElementById("panNumber").value === "" ? null : document.getElementById("panNumber").value
              }
            const addClientUrl = `${authenticate.apiUrl}/api/client/`;
            fetch(addClientUrl, {
                method: "POST",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                body: JSON.stringify(clientData)
            }).then(response => {
                if (response.status === 200) {
                    authenticate.setMessage({message:"Client Added Successfully", navigateHome:true});
                } else {
                    authenticate.setMessage({message:response.statusText});
                }
            });
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="addClientSection">
                <div className="heading">
                    <div>Add Client</div>
                </div>
                <div className="clientSection">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="submit" id="addClient" className="navbarButton" value="Add Client" onClick={handleAddClient} />
                </div>
            </section>}
        </>
    )
}

export default AddClient
