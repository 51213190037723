import React from 'react';
import '../css/selectInput.css';

const SelectInput = (props) => {
  return (
    <>
      <div>
        <div>
          <label htmlFor={props.id}>{props.label}</label>
        </div>
        <div>
          {props.options ? props.onChange !== false && props.value !== false ? <select id={props.id} value={props.value} onChange={props.onChange}>{props.options.value.map(ele => {
            return <option key={ele} value={ele}>{ele}</option>
          })}</select> : <select id={props.id}>
            {props.options.value.map(ele => {
              return <option key={ele} value={ele}>{ele}</option>
            })}
          </select> : props.onChange !== false && props.value !== false ? <select id={props.id} value={props.value} onChange={props.onChange}>
            <option value="select">Select</option>
            {props.id === "searchGcn" && props.gcnNumbers.map(ele => {
              return <option key={ele} value={ele}>{ele}</option>
            })}
            {props.id === "SearchBill" && props.billNumbers.map(ele => {
              return <option key={ele} value={ele}>{ele}</option>
            })}
            {props.id === "searchThc" && props.thcNumbers.map(ele => {
              return <option key={ele} value={ele}>{ele}</option>
            })}
            {(props.id === "deliveryState" || props.id === "pickupState") && props.states.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.stateName}</option>
            })}
          </select> : props.onChange !== false ? <select id={props.id} onChange={props.onChange}>
            <option value="select">Select</option>
            {props.id === "billNo" && props.billNumbers.map(ele => {
              return <option key={ele} value={`S-${ele}`}>{ele}</option>
            })}
            {(props.id === "consignerName" || props.id === "consigneeName") && props.clients.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.clientName}</option>
            })}
            {(props.id === "truckNo") && props.truckDetail.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.pk}</option>
            })}
            {(props.id === "broker") && props.broker.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.brokerName}</option>
            })}
            {(props.id === "truckOwner") && props.owner.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.ownerName}</option>
            })}
            {(props.id === "driverName") && props.driver.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.driverName}</option>
            })}
            {(props.id === "savedAddresses") && props.adress.map(ele => {
              return <option key={ele.pk} value={ele.fields.address}>{ele.fields.address}</option>
            })}
          </select> : <select id={props.id}>
            <option value="select">Select</option>
            {(props.id === "deliveryState" || props.id === "pickupState") && props.states.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.stateName}</option>
            })}
            {(props.id === "pickupLocation" || props.id === "deliveryLocation") && props.locations.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.location}</option>
            })}
            {props.id === "methodOfPkg" && props.packageTypes.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.package}</option>
            })}
            {props.id === "description" && props.descriptions.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.product}</option>
            })}
            {(props.id === "broker") && props.broker.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.brokerName}</option>
            })}
            {(props.id === "owner") && props.owner.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.ownerName}</option>
            })}
            {(props.id === "driver") && props.driver.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.driverName}</option>
            })}
            {(props.id === "billTo") && props.clients.map(ele => {
              return <option key={ele.pk} value={ele.pk}>{ele.fields.clientName}</option>
            })}
          </select>}
        </div>
      </div>
    </>
  )
}

export default SelectInput
