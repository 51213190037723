import React, { useContext, useEffect } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';
import { utils, writeFileXLSX } from 'xlsx';

const MonthlyBooking = () => {
    const authenticate = useContext(SstContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            } else {
                document.title = "SSTransline | Monthly Booking Report";
            }
        }
        // eslint-disable-next-line
    }, []);
    const items = [{ "label": "From", "id": "from", "type": "date" }, { "label": "To", "id": "to", "type": "date" }];
    const validateMonthlyBookingReport = () => {
        if (document.getElementById("from").value === "") {
            authenticate.setMessage({ message: "From Cannot Be Blank" });
            return false;
        } else if (document.getElementById("to").value === "") {
            authenticate.setMessage({ message: "To Cannot Be Blank" });
            return false;
        }
        return true;
    }
    const handleMonthlyBookingReport = () => {
        if (validateMonthlyBookingReport()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const monthlyBookingReportUrl = `${authenticate.apiUrl}/api/monthlybooking/?from=${document.getElementById("from").value}&to=${document.getElementById("to").value}`;
            fetch(monthlyBookingReportUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    // setErrorMsg(response.statusText);
                }
            }).then(data => {
                let parsedData = JSON.parse(data);
                let reportData = [];
                let sumOfTruckHire = 0;
                let sumOfBillAmount = 0;
                let sumOfAdvance = 0;
                let sumOfBalance = 0;
                parsedData.forEach(element => {
                    sumOfTruckHire += element["Truck Hire"] === null ? 0 : parseInt(element["Truck Hire"]);
                    sumOfBillAmount += element["Bill Amount"] === null ? 0 : parseInt(element["Bill Amount"]);
                    sumOfAdvance += element.Advance === null ? 0 : parseInt(element.Advance);
                    sumOfBalance += element.Balance === null ? 0 : parseInt(element.Balance);
                    reportData.push(element);
                });
                reportData.push({"LR No" : null, "Date" : null, "From" : null, "To" : null, "Invoice No" : null, "Bill To" : null, "Vehical No" : null, "Bill No" : null, "Truck Hire" : sumOfTruckHire, "Advance" : sumOfAdvance, "Balance" : sumOfBalance, "Bill Amount" : sumOfBillAmount});
                const worksheet = utils.json_to_sheet(reportData);
                const workbook = utils.book_new();
                utils.book_append_sheet(workbook, worksheet, "Sheet 1");
                writeFileXLSX(workbook, `MonthlyBookingReport.xlsx`);
                authenticate.setMessage(false);
            });
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="monthlyBookingInput">
                <div className="heading">
                    <div>Monthly Booking</div>
                </div>
                <div className="monthlyBooking">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="submit" id="monthlyBookingBtn" className="navbarButton" value="Report" onClick={handleMonthlyBookingReport} />
                </div>
            </section>}
        </>
    )
}

export default MonthlyBooking
