import React, {useContext, useState, useEffect} from 'react';
import '../css/home.css';
import SstContext from '../contexts/SstContext';
import Spinner from './Spinner';

const Home = (props) => {
    const authenticate = useContext(SstContext);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const handleLogin = () => {
        authenticate.handleProgress(10);
        setLoading(true);
        let name =  document.getElementById("userID").value;
        let loginData = {
            "name" : document.getElementById("userID").value,
            "password" : document.getElementById("password").value
        }
        const url = `${authenticate.apiUrl}/api/auth/login/`;
        fetch(url, {
            method:"POST",
            headers: {'Content-Type': 'application/json'},
            body : JSON.stringify(loginData)
        }).then(response => {
            setLoading(false);
            if (response.status === 200){
                authenticate.handleProgress(50)
                return response.json();
            }else{
                authenticate.handleProgress(100);
                setErrorMsg(response.statusText);
            }
        }).then(data => {
            try{
                authenticate.handleProgress(70);
                document.cookie = `authToken=${data.authToken}`;
                authenticate.handleAuthenticate(true);
                authenticate.handleUserName(name);
                authenticate.handleAdmin(data.admin);
                document.body.className = "backgroundWhite";
                authenticate.handleProgress(100);
            }catch{
                authenticate.handleProgress(100);
            }
        });
    }
    useEffect(() => {
        document.title = "SSTransline | Home";
        // eslint-disable-next-line
    }, []);
    return (
        <>
            {!authenticate.isLogin && <section id="HomeSection">
                <div id="sstSymbolHomeDiv">
                    <img src={props.symbol} alt="SST Symbol" id="sstSymbolHome"/>
                </div>
                <section id="LoginSection">
                    <div>
                        {errorMsg}
                    </div>
                    <div>
                        <label htmlFor="userID" className="fontSizeColor">UserID</label>
                    </div>
                    <div>
                        <input type="text" id="userID" className="textBox" />
                    </div>
                    <div>
                        <label htmlFor="password" className="fontSizeColor">Password</label>
                    </div>
                    <div>
                        <input type="password" id="password" className="textBox" />
                    </div>
                    <div id="LoginBtnContainer">
                        {!loading?<input type="button" value="Lets Go" className="navbarButton" id="LoginBtn" onClick={handleLogin} /> : <Spinner />}
                    </div>
                </section>
            </section>}
        </>
    )
}

export default Home
