import React, { useContext, useEffect, useState } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate, useParams } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';

const ThcEntry = (props) => {
    const authenticate = useContext(SstContext);
    const [thcNumber, setThcNumber] = useState("");
    const [truckDetail, setTruckDetail] = useState([]);
    const [broker, setBroker] = useState([]);
    const [owner, setOwner] = useState([]);
    const [driver, setDriver] = useState([]);
    const [truckHire, setTruckHire] = useState(0);
    const [advance, setAdvance] = useState(0);
    const [balance, setBalance] = useState(0);
    const [selectedThcData, setSelectedThcData] = useState([]);
    const pageData = useParams();
    const navigate = useNavigate();
    const handleThcNumber = (event) => {
        setThcNumber(event.target.value);
    }
    const handleBalanceCalculation = (event) => {
        let refrence = {
            "truckHire": setTruckHire,
            "advance": setAdvance
        };
        if (event.target.value !== "" && parseInt(event.target.value) > 0 && event.target.id !== "balance") {
            let localTruckHire = truckHire;
            let localAdvance = advance;
            if (event.target.id === "truckHire") {
                localTruckHire = event.target.value;
            } else if (event.target.id === "advance") {
                localAdvance = event.target.value;
            }
            setBalance(parseInt(localTruckHire) - parseInt(localAdvance));
            let removedZeroes = parseInt(event.target.value);
            setTimeout(() => {
                refrence[event.target.id](removedZeroes);
            }, 10)
        } else if (event.target.value === "" && event.target.id !== "balance") {
            let localTruckHire = truckHire;
            let localAdvance = advance;
            if (event.target.id === "truckHire") {
                localTruckHire = 0;
            } else if (event.target.id === "advance") {
                localAdvance = 0;
            }
            setBalance(parseInt(localTruckHire) - parseInt(localAdvance))
            refrence[event.target.id](0);
        }
    }
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (authenticate.admin) {
                authenticate.handleProgress(10);
                const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
                const truckDetailUrl = `${authenticate.apiUrl}/api/truckdetail/`;
                fetch(truckDetailUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    let parsedData = JSON.parse(data);
                    setTruckDetail(parsedData);
                });
                authenticate.handleProgress(40);
                const brokerUrl = `${authenticate.apiUrl}/api/broker/`;
                fetch(brokerUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    let parsedData = JSON.parse(data);
                    setBroker(parsedData);
                });
                authenticate.handleProgress(60);
                const ownerUrl = `${authenticate.apiUrl}/api/owner/`;
                fetch(ownerUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    let parsedData = JSON.parse(data);
                    setOwner(parsedData);
                });
                authenticate.handleProgress(80);
                const driverUrl = `${authenticate.apiUrl}/api/driver/`;
                fetch(driverUrl, {
                    method: "GET",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                }).then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        // setErrorMsg(response.statusText);
                    }
                }).then(data => {
                    let parsedData = JSON.parse(data);
                    setDriver(parsedData);
                });
                authenticate.handleProgress(100);
            } else {
                navigate("/");
            }
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (authenticate.admin) {
                const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
                if (!props.edit) {
                    document.title = "SSTransline | THC Entry";
                    const thcNumbersUrl = `${authenticate.apiUrl}/api/thc/?allNumbersOnly=True`;
                    fetch(thcNumbersUrl, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            // setErrorMsg(response.statusText);
                        }
                    }).then(data => {
                        let parsedData = JSON.parse(data);
                        let latestThcNumber = `T-${parsedData.latest_thc + 1}`;
                        setThcNumber(latestThcNumber);
                        document.getElementById("date").value = "";
                        document.getElementById("truckNo").value = "select";
                        document.getElementById("vehicleTonnage").value = "";
                        document.getElementById("broker").value = "select";
                        document.getElementById("truckOwner").value = "select";
                        document.getElementById("driverName").value = "select";
                        setTruckHire(0);
                        setAdvance(0);
                        setBalance(0);
                        document.getElementById("balancePaidDate").value = "";
                        document.getElementById("engineNo").value = "";
                        document.getElementById('chasisNo').value = "";
                        document.getElementById('insPolicyNumber').value = "";
                        document.getElementById('insValidity').value = "";
                        document.getElementById('fitnessValidity').value = "";
                        document.getElementById('permitValidity').value = "";
                        document.getElementById('pucValidity').value = "";
                        document.getElementById('companyMake').value = "";
                        document.getElementById('brokerAddress').value = "";
                        document.getElementById('brokerMobileNumber').value = "";
                        document.getElementById('brokerPan').value = "";
                        document.getElementById("ownerAddress").value = "";
                        document.getElementById('ownerPanNumber').value = "";
                        document.getElementById('ownerMobileNumber').value = "";
                        document.getElementById("driverAddress").value = "";
                        document.getElementById('driverLiscenceNumber').value = "";
                        document.getElementById('driverMobileNumber').value = "";
                    });
                } else {
                    document.title = "SSTransline | THC Modify";
                    const thcNumbersUrl = `${authenticate.apiUrl}/api/thc/?thcNo=${pageData.thcNo}`;
                    fetch(thcNumbersUrl, {
                        method: "GET",
                        headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
                    }).then(response => {
                        if (response.status === 200) {
                            return response.json();
                        } else {
                            // setErrorMsg(response.statusText);
                        }
                    }).then(data => {
                        let parsedData = JSON.parse(data);
                        setSelectedThcData(parsedData);
                        setThcNumber(parsedData[0].pk);
                        document.getElementById("date").value = parsedData[0].fields.date;
                        document.getElementById("vehicleTonnage").value = parsedData[0].fields.vehicleTonnage === null ? "" : parsedData[0].fields.vehicleTonnage;
                        setTruckHire(parsedData[0].fields.truckHire === null ? 0 : parsedData[0].fields.truckHire);
                        setAdvance(parsedData[0].fields.advance === null ? 0 : parsedData[0].fields.advance);
                        setBalance(parsedData[0].fields.balance === null ? 0 : parsedData[0].fields.balance);
                        document.getElementById("balancePaidDate").value = parsedData[0].fields.balancePaidDate === null ? "" : parsedData[0].fields.balancePaidDate;
                    });
                }
            } else {
                navigate("/");
            }
        }
    }, [props.edit]);
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (authenticate.admin) {
                if (selectedThcData.length !== 0) {
                    if (truckDetail.length !== 0) {
                        document.getElementById("truckNo").value = selectedThcData[0].fields.truckdetails;
                        truckDetail.forEach(element => {
                            if (element.pk === selectedThcData[0].fields.truckdetails) {
                                document.getElementById("engineNo").value = element.fields.driverAddress === null ? "" : element.fields.engineNumber;
                                document.getElementById('chasisNo').value = element.fields.chasisNumber === null ? "" : element.fields.chasisNumber;
                                document.getElementById('insPolicyNumber').value = element.fields.insurancePolicyNumber === null ? "" : element.fields.insurancePolicyNumber;
                                document.getElementById('insValidity').value = element.fields.insuranceValidity === null ? "" : element.fields.insuranceValidity;
                                document.getElementById('fitnessValidity').value = element.fields.fitnessValidity === null ? "" : element.fields.fitnessValidity;
                                document.getElementById('permitValidity').value = element.fields.permitValidity === null ? "" : element.fields.permitValidity;
                                document.getElementById('pucValidity').value = element.fields.pucValidity === null ? "" : element.fields.pucValidity;
                                document.getElementById('companyMake').value = element.fields.companyMake === null ? "" : element.fields.companyMake;
                            }
                        });
                    }
                    if (broker.length !== 0) {
                        document.getElementById("broker").value = selectedThcData[0].fields.broker === null ? "select" : selectedThcData[0].fields.broker;
                        broker.forEach(element => {
                            if (element.pk === selectedThcData[0].fields.broker) {
                                document.getElementById('brokerAddress').value = element.fields.brokerAddress === null ? "" : element.fields.brokerAddress;
                                document.getElementById('brokerMobileNumber').value = element.fields.brokerContactNumber === null ? "" : element.fields.brokerContactNumber;
                                document.getElementById('brokerPan').value = element.fields.panNo === null ? "" : element.fields.panNo;
                            }
                        });
                    }
                    if (owner.length !== 0) {
                        document.getElementById("truckOwner").value = selectedThcData[0].fields.owner === null ? "select" : selectedThcData[0].fields.owner;
                        owner.forEach(element => {
                            if (element.pk === selectedThcData[0].fields.owner) {
                                document.getElementById("ownerAddress").value = element.fields.ownerAddress === null ? "" : element.fields.ownerAddress;
                                document.getElementById('ownerPanNumber').value = element.fields.panNo === null ? "" : element.fields.panNo;
                                document.getElementById('ownerMobileNumber').value = element.fields.ownerContactNumber === null ? "" : element.fields.ownerContactNumber;
                            }
                        });
                    }
                    if (driver.length !== 0) {
                        document.getElementById("driverName").value = selectedThcData[0].fields.driver === null ? "select" : selectedThcData[0].fields.driver;
                        driver.forEach(element => {
                            if (element.pk === selectedThcData[0].fields.driver) {
                                document.getElementById("driverAddress").value = element.fields.driverAddress === null ? "" : element.fields.driverAddress;
                                document.getElementById('driverLiscenceNumber').value = element.fields.liscenceNumber === null ? "" : element.fields.liscenceNumber;
                                document.getElementById('driverMobileNumber').value = element.fields.driverContactNumber === null ? "" : element.fields.driverContactNumber;
                            }
                        });
                    }
                }
            }
        }
    }, [selectedThcData, owner, driver, truckDetail, broker]);
    const validateThc = () => {
        if (document.getElementById("thcNo").value === "") {
            authenticate.setMessage({message :"Thc Cannot Be Blank"});
            return false;
        } else if (document.getElementById("date").value === "") {
            authenticate.setMessage({message :"Date Cannot Be Blank"});
            return false;
        } else if (document.getElementById("truckNo").value === "select") {
            authenticate.setMessage({message :"Truck Number Cannot Be Blank"});
            return false;
        }
        return true;
    }
    const handleSubmit = () => {
        if (validateThc()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            let thcData = {
                "thcNo": document.getElementById('thcNo').value,
                "date": document.getElementById('date').value,
                "truckdetails": document.getElementById('truckNo').value,
                "vehicleTonnage": document.getElementById("vehicleTonnage").value === "" ? null : document.getElementById("vehicleTonnage").value,
                "broker": document.getElementById("broker").value === "select" ? null : document.getElementById("broker").value,
                "owner": document.getElementById("truckOwner").value === "select" ? null : document.getElementById("truckOwner").value,
                "driver": document.getElementById("driverName").value === "select" ? null : document.getElementById("driverName").value,
                "truckHire": truckHire,
                "advance": advance,
                "balance": balance,
                "balancePaidDate": document.getElementById("balancePaidDate").value === "" ? null : document.getElementById("balancePaidDate").value
            }
            if (!props.edit) {
                const addThcUrl = `${authenticate.apiUrl}/api/thc/`;
                fetch(addThcUrl, {
                    method: "POST",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                    body: JSON.stringify(thcData)
                }).then(response => {
                    if (response.status === 200) {
                        authenticate.setMessage({message:"Thc Submitted Successfully", navigateHome:true});
                    } else {
                        authenticate.setMessage({message:response.statusText});
                    }
                });
            } else {
                const updateThcUrl = `${authenticate.apiUrl}/api/thc/`;
                fetch(updateThcUrl, {
                    method: "PUT",
                    headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader },
                    body: JSON.stringify(thcData)
                }).then(response => {
                    if (response.status === 200) {
                        authenticate.setMessage({message:"Thc Updated Successfully", navigateHome:true});
                    } else {
                        authenticate.setMessage({message:response.statusText});
                    }
                });
            }
        }
    }
    const handleBrokerChange = (event) => {
        if (event.target.value !== "select" && broker.length !== 0) {
            broker.forEach(element => {
                if (element.pk === parseInt(event.target.value)) {
                    document.getElementById('brokerAddress').value = element.fields.brokerAddress === null ? "" : element.fields.brokerAddress;
                    document.getElementById('brokerMobileNumber').value = element.fields.brokerContactNumber === null ? "" : element.fields.brokerContactNumber;
                    document.getElementById('brokerPan').value = element.fields.panNo === null ? "" : element.fields.panNo;
                }
            });
        }
    }
    const handleOwnerChange = (event) => {
        if (event.target.value !== "select" && owner.length !== 0) {
            owner.forEach(element => {
                if (element.pk === parseInt(event.target.value)) {
                    document.getElementById("ownerAddress").value = element.fields.ownerAddress === null ? "" : element.fields.ownerAddress;
                    document.getElementById('ownerPanNumber').value = element.fields.panNo === null ? "" : element.fields.panNo;
                    document.getElementById('ownerMobileNumber').value = element.fields.ownerContactNumber === null ? "" : element.fields.ownerContactNumber;
                }
            });
        }
    }
    const handleDriverChange = (event) => {
        if (event.target.value !== "select" && driver.length !== 0) {
            driver.forEach(element => {
                if (element.pk === parseInt(event.target.value)) {
                    document.getElementById("driverAddress").value = element.fields.driverAddress === null ? "" : element.fields.driverAddress;
                    document.getElementById('driverLiscenceNumber').value = element.fields.liscenceNumber === null ? "" : element.fields.liscenceNumber;
                    document.getElementById('driverMobileNumber').value = element.fields.driverContactNumber === null ? "" : element.fields.driverContactNumber;
                }
            });
        }
    }
    const handleTruckNumberChange = (event) => {
        if (event.target.value !== "select" && truckDetail.length !== 0) {
            truckDetail.forEach(element => {
                if (element.pk === event.target.value) {
                    document.getElementById("engineNo").value = element.fields.driverAddress === null ? "" : element.fields.engineNumber;
                    document.getElementById('chasisNo').value = element.fields.chasisNumber === null ? "" : element.fields.chasisNumber;
                    document.getElementById('insPolicyNumber').value = element.fields.insurancePolicyNumber === null ? "" : element.fields.insurancePolicyNumber;
                    document.getElementById('insValidity').value = element.fields.insuranceValidity === null ? "" : element.fields.insuranceValidity;
                    document.getElementById('fitnessValidity').value = element.fields.fitnessValidity === null ? "" : element.fields.fitnessValidity;
                    document.getElementById('permitValidity').value = element.fields.permitValidity === null ? "" : element.fields.permitValidity;
                    document.getElementById('pucValidity').value = element.fields.pucValidity === null ? "" : element.fields.pucValidity;
                    document.getElementById('companyMake').value = element.fields.companyMake === null ? "" : element.fields.companyMake;
                    document.getElementById('broker').value = element.fields.broker === null ? "select" : element.fields.broker;
                    broker.forEach(ele => {
                        if (ele.pk === parseInt(element.fields.broker)) {
                            document.getElementById('brokerAddress').value = ele.fields.brokerAddress === null ? "" : ele.fields.brokerAddress;
                            document.getElementById('brokerMobileNumber').value = ele.fields.brokerContactNumber === null ? "" : ele.fields.brokerContactNumber;
                            document.getElementById('brokerPan').value = ele.fields.panNo === null ? "" : ele.fields.panNo;
                        }
                    });
                    document.getElementById('truckOwner').value = element.fields.owner === null ? "select" : element.fields.owner;
                    owner.forEach(ele => {
                        if (ele.pk === parseInt(element.fields.owner)) {
                            document.getElementById("ownerAddress").value = ele.fields.ownerAddress === null ? "" : ele.fields.ownerAddress;
                            document.getElementById('ownerPanNumber').value = ele.fields.panNo === null ? "" : ele.fields.panNo;
                            document.getElementById('ownerMobileNumber').value = ele.fields.ownerContactNumber === null ? "" : ele.fields.ownerContactNumber;
                        }
                    });
                    document.getElementById('driverName').value = element.fields.driver === null ? "select" : element.fields.driver;
                    driver.forEach(ele => {
                        if (ele.pk === parseInt(element.fields.driver)) {
                            document.getElementById("driverAddress").value = ele.fields.driverAddress === null ? "" : ele.fields.driverAddress;
                            document.getElementById('driverLiscenceNumber').value = ele.fields.liscenceNumber === null ? "" : ele.fields.liscenceNumber;
                            document.getElementById('driverMobileNumber').value = ele.fields.driverContactNumber === null ? "" : ele.fields.driverContactNumber;
                        }
                    });
                }
            });
        }
    }
    const items = {
        "Truck Hire Challan": {
            "className": "thcSection",
            "elements": [{ "label": "THC No", "id": "thcNo", "type": "text", "value": thcNumber, "onChange": handleThcNumber }, { "label": "Date", "id": "date", "type": "date" }, { "label": "Truck No", "id": "truckNo", "type": "select", "onChange": handleTruckNumberChange }, { "label": "Vehicle Tonnage", "id": "vehicleTonnage", "type": "text" }]
        },
        "Broker/Owner And Driver Details": {
            "className": "thcSection",
            "elements": [{ "label": "Broker", "id": "broker", "type": "select", "onChange": handleBrokerChange }, { "label": "Broker Address", "id": "brokerAddress", "type": "textArea", "cols": "30", "rows": "10" }, { "label": "Broker Mobile No", "id": "brokerMobileNumber", "type": "text" }, { "label": "Broker Pan", "id": "brokerPan", "type": "text" }, { "label": "Truck Owner", "id": "truckOwner", "type": "select", "onChange": handleOwnerChange }, { "label": "Owner Address", "id": "ownerAddress", "type": "textArea", "cols": "30", "rows": "10" }, { "label": "Owner Pan No", "id": "ownerPanNumber", "type": "text" }, { "label": "Owner Mobile No", "id": "ownerMobileNumber", "type": "text" }, { "label": "Driver Name", "id": "driverName", "type": "select", "onChange": handleDriverChange }, { "label": "Driver Address", "id": "driverAddress", "type": "textArea", "cols": "30", "rows": "10" }, { "label": "Driver Liscence No", "id": "driverLiscenceNumber", "type": "text" }, { "label": "Driver Mobile No", "id": "driverMobileNumber", "type": "text" }]
        },
        "Truck Details": {
            "className": "thcSection",
            "elements": [{ "label": "Engine No", "id": "engineNo", "type": "text" }, { "label": "Chasis No", "id": "chasisNo", "type": "text" }, { "label": "Ins Policy No", "id": "insPolicyNumber", "type": "text" }, { "label": "Ins Validity", "id": "insValidity", "type": "date" }, { "label": "Fitness Validity", "id": "fitnessValidity", "type": "date" }, { "label": "Permit Validity", "id": "permitValidity", "type": "date" }, { "label": "PUC Validity", "id": "pucValidity", "type": "date" }, { "label": "Comapany Make", "id": "companyMake", "type": "text" }]
        },
        "Vehicle Charges": {
            "className": "thcSection",
            "elements": [{ "label": "Truck Hire", "id": "truckHire", "type": "number", "value": truckHire, "onChange": handleBalanceCalculation }, { "label": "Advance", "id": "advance", "type": "number", "value": advance, "onChange": handleBalanceCalculation }, { "label": "Balance", "id": "balance", "type": "number", "value": balance, "onChange": handleBalanceCalculation }, { "label": "Balance Paid Date", "id": "balancePaidDate", "type": "date" }]
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <div>
                <div className="heading">
                    <div>Truck Hire Challan</div>
                </div>
                <div className={items["Truck Hire Challan"].className}>
                    {items["Truck Hire Challan"].elements.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} truckDetail={truckDetail} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div className="heading">
                    <div>Broker/Owner And Driver Details</div>
                </div>
                <div className={items["Broker/Owner And Driver Details"].className}>
                    {items["Broker/Owner And Driver Details"].elements.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} broker={broker} owner={owner} driver={driver} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div className="heading">
                    <div>Truck Details</div>
                </div>
                <div className={items["Truck Details"].className}>
                    {items["Truck Details"].elements.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div className="heading">
                    <div>Vehicle Charges</div>
                </div>
                <div className={items["Vehicle Charges"].className}>
                    {items["Vehicle Charges"].elements.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} /> : <LabelInput key={element.id} id={element.id} value={element.value !== "" ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="submit" id="submitThc" className="navbarButton" value="Submit THC" onClick={handleSubmit} />
                </div>
            </div>}
        </>
    )
}

export default ThcEntry
