import React, { useContext, useEffect, useState } from 'react';
import SstContext from '../contexts/SstContext';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';
import '../css/trackAndViewPod.css'

const TrackAndViewPod = () => {
    const authenticate = useContext(SstContext);
    const [location, setLocation] = useState(false);
    const [deliveryStatus, setDeliveryStatus] = useState("Not Delivered Yet");
    const [latitude, setLatitude] = useState(false);
    const [longitude, setLongitude] = useState(false);
    useEffect(() => {
        document.title = "SSTransline | Track And View POD";
        // eslint-disable-next-line
    }, []);
    const items = [{ "label": "Gcn No", "id": "gcnNO", "type": "text" }];
    const validateGcnNo = () => {
        if (document.getElementById("gcnNO").value === "") {
            authenticate.setMessage({ message: "Gcn Number Cannot Be Blank" });
            return false;
        }
        return true;
    }
    const handleviewPod = () => {
        if (validateGcnNo()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const viewPodUrl = `${authenticate.apiUrl}/api/pod/?gcnNo=${document.getElementById("gcnNO").value.toLowerCase()}`;
            fetch(viewPodUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.blob();
                } else {
                    authenticate.setMessage({ message: "POD Not Uploaded Yet" });
                }
            }).then(data => {
                let _url = window.URL.createObjectURL(data);
                window.open(_url, "_blank", "popup=no");
                window.URL.revokeObjectURL(_url);
            });
        }
    }
    const handleTrackPod = () => {
        if (validateGcnNo()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const viewPodUrl = `${authenticate.apiUrl}/api/track/?gcnNo=${document.getElementById("gcnNO").value}`;
            fetch(viewPodUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    authenticate.setMessage({ message: "POD Not Uploaded Yet" });
                }
            }).then(data => {
                let parsedData = JSON.parse(data);
                if (parsedData.delivered) {
                    let DeliveryDate = "NA";
                    if (parsedData.deliveryDate !== 'None') {
                        DeliveryDate = new Date(parsedData.deliveryDate);
                        DeliveryDate = `${('0' + DeliveryDate.getDate()).slice(-2)}-${('0' + (DeliveryDate.getMonth() + 1)).slice(-2)}-${DeliveryDate.getFullYear().toString()}`;
                    }
                    setDeliveryStatus(`Delivered On Date - ${DeliveryDate}`);
                    setLocation("delivered");
                } else {
                    setLocation(parsedData.location);
                    if (parsedData.location !== "NA"){
                        setDeliveryStatus("Not delivered yet");
                        setLatitude(parsedData.latitude);
                        setLongitude(parsedData.longitude);
                    }
                }
            });
        }
    }
    return (
        <>
            <section id="trackAndViewPodSection">
                <div className="heading">
                    <div>Track And View POD</div>
                </div>
                <div className="trackingInputSection">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="button" id="trackBtn" className="navbarButton" value="Track" onClick={handleTrackPod} />
                    <input type="button" id="viewPod" className="navbarButton" value="View POD" onClick={handleviewPod} />
                </div>
                {location && <div id="locationSection"><div>Delivery Status : {deliveryStatus}</div>
                {location !== "delivered" && <div>Last Location : {location}</div>}
                {latitude && longitude && <iframe
                    title="location viewer"
                    width="80%"
                    height="100%"
                    frameborder="0" style={{border:'1px solid #F0F0F0'}}
                    src={`https://www.google.com/maps/embed/v1/search?key=AIzaSyBrkh_CV460RcGpsQ4sZbcE77L-1CHXf0I&q=${latitude}, ${longitude}&zoom=12`} allowfullscreen>
                </iframe>}</div>}
            </section>
        </>
    )
}

export default TrackAndViewPod
