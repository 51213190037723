import React from 'react';
import '../css/labelAdd.css';

const LabelAdd = (props) => {
    const handleAddExpense = () => {
        if (document.getElementById("expenses").value !== "" && !(props.expenses.hasOwnProperty(document.getElementById("expenses").value))) {
            let local_expenses = Object.assign({}, props.expenses);
            local_expenses[document.getElementById("expenses").value] = 0;
            props.setExpenses(local_expenses);
        }
    }
    const handleInputChanged = (element, event) => {
        if (event.target.value !== "" && parseInt(event.target.value) > 0) {
            event.target.value = parseInt(event.target.value);
            let local_expenses = props.expenses;
            local_expenses[element] = event.target.value;
            props.setExpenses(local_expenses);
            let localTotalExpense = 0;
            Object.values(local_expenses).forEach(ele => {
                localTotalExpense += parseInt(ele);
            });
            props.setBalance(props.truckHire - localTotalExpense);
            props.setTotalExpense(localTotalExpense);
        } else {
            let local_expenses = props.expenses;
            local_expenses[element] = 0;
            props.setExpenses(local_expenses);
            let localTotalExpense = 0;
            Object.values(local_expenses).forEach(ele => {
                localTotalExpense += parseInt(ele);
            });
            props.setBalance(props.truckHire - localTotalExpense);
            props.setTotalExpense(localTotalExpense);
        }
    }
    const handleRemoveExpense = (element) => {
        let local_expenses = Object.assign({}, props.expenses);
        delete local_expenses[element];
        props.setExpenses(local_expenses);
        let localTotalExpense = 0;
        Object.values(local_expenses).forEach(ele => {
            localTotalExpense += parseInt(ele);
        });
        props.setBalance(props.truckHire - localTotalExpense);
        props.setTotalExpense(localTotalExpense);
    }
    return (
        <div>
            <div>
                <label htmlFor={props.id}>{props.label}</label>
            </div>
            <div id="addExpenses">
                <div>
                    <input type="text" name="Expenses" id={props.id} />
                    <div>
                        <input type="button" value="+" className="addButton" id="addExpenseBtn" onClick={handleAddExpense} />
                    </div>
                </div>
                {Object.keys(props.expenses).map(element => {
                    return <div key={element} className="paddingTopPointTwo"><label>{element}</label>
                        <input type="number" className="widthChange" value={props.expenses[element]} onChange={(event) => { handleInputChanged(element, event) }} />
                        <div>
                            <input type="button" value="-" className="removeButton" onClick={() => { handleRemoveExpense(element) }} />
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}

export default LabelAdd
