import React, { useContext, useEffect } from 'react';
import SstContext from '../contexts/SstContext';
import { useNavigate } from 'react-router-dom';
import LabelInput from './LabelInput';
import SelectInput from './SelectInput';
import { utils, writeFileXLSX } from 'xlsx';

const BillSubmissionReport = () => {
    const authenticate = useContext(SstContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (!authenticate.isLogin) {
            navigate("/");
        } else {
            if (!authenticate.admin) {
                navigate("/");
            } else {
                document.title = "SSTransline | Bill Submission Report";
            }
        }
        // eslint-disable-next-line
    }, []);
    const items = [{ "label": "From", "id": "from", "type": "date" }, { "label": "To", "id": "to", "type": "date" }];
    const validateBillSubmissionReport = () => {
        if (document.getElementById("from").value === "") {
            authenticate.setMessage({ message: "From Cannot Be Blank" });
            return false;
        } else if (document.getElementById("to").value === "") {
            authenticate.setMessage({ message: "To Cannot Be Blank" });
            return false;
        }
        return true;
    }
    const handleBillSubmissionReport = () => {
        if (validateBillSubmissionReport()) {
            const authHeader = document.cookie.split('; ').find((row) => row.startsWith('authToken='))?.split('=')[1];
            const billSubmissionReportUrl = `${authenticate.apiUrl}/api/bill/?from=${document.getElementById("from").value}&to=${document.getElementById("to").value}`;
            fetch(billSubmissionReportUrl, {
                method: "GET",
                headers: { 'Content-Type': 'application/json', 'Auth-Token': authHeader }
            }).then(response => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    // setErrorMsg(response.statusText);
                }
            }).then(data => {
                let parsedData = JSON.parse(data);
                let reportData = [];
                parsedData.forEach(element => {
                    reportData.push(element);
                });
                const worksheet = utils.json_to_sheet(reportData);
                const workbook = utils.book_new();
                utils.book_append_sheet(workbook, worksheet, "Sheet 1");
                writeFileXLSX(workbook, `BillSubmissionReport.xlsx`);
                authenticate.setMessage(false);
            });
        }
    }
    return (
        <>
            {authenticate.isLogin && authenticate.admin && <section id="billSubmissionInput">
                <div className="heading">
                    <div>Bill Submission Report</div>
                </div>
                <div className="billSubmission">
                    {items.map(element => {
                        return element.type === "select" ? <SelectInput key={element.id} id={element.id} value={element.value ? element.value : false} onChange={element.onChange ? element.onChange : false} label={element.label} options={element.options ? element.options : false} /> : <LabelInput key={element.id} id={element.id} value={element.value ? element.value : false} maxLength={element.maxLength ? element.maxLength : false} onChange={element.onChange ? element.onChange : false} divId={element.divId ? element.divId : false} label={element.label} readOnly={element.readOnly ? element.readOnly : false} type={element.type} />
                    })}
                </div>
                <div id="btnContainer">
                    <input type="submit" id="billSubmissionReportBtn" className="navbarButton" value="Report" onClick={handleBillSubmissionReport} />
                </div>
            </section>}
        </>
    )
}

export default BillSubmissionReport
